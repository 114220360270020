import { IResourceComponentsProps } from "@refinedev/core";
import { ICustomerRecord, ICustomerResponse } from '@interfaces';
import { Card, Group, Title } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { customerSchema } from '@components/data/cards/CustomerCard/CustomerCard';
import { useIdentity } from '@components/data/Identity.context';
import { CustomerFormFields } from './form/CustomerFormFields';

export function CustomerEdit({ initialData: record }: IResourceComponentsProps<ICustomerResponse>) {
  const { owner } = useIdentity();

  const form = useForm<Partial<ICustomerRecord>>({
    validate: yupResolver(customerSchema(owner)),
    initialValues: {
      locale: record?.data.locale ?? 'en',
      name: record?.data.name ?? '',
      email: record?.data.email ?? '',
      phone_number: record?.data.phone_number ?? '',
      marked_status: record?.data.marked_status ?? 'None',
      settings: {
        feedback_exclude_automation: record?.data.settings.feedback_exclude_automation ?? false,
        refunds_exclude_automation: record?.data.settings.refunds_exclude_automation ?? false,
        notifications_unsubscribed: record?.data.settings.notifications_unsubscribed ?? false,
      }
    }
  });

  return <ResourceForm form={form}>
    <Group mb="lg">
      <Title order={2}>New Customer</Title>
    </Group>

    <Card shadow="sm" radius="sm">
      <Card.Section withBorder p="md">
        <CustomerFormFields form={form} />
      </Card.Section>

      <Card.Section px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Card>
  </ResourceForm>
}
