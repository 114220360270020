import * as Yup from 'yup';
import { difference } from 'lodash';

export default Yup.object().shape({
  theme_color: Yup.string().required('Select a color'),
  about_us: Yup.string(),
  term_of_use: Yup.string(),
  private_policy: Yup.string(),
  translations: Yup.lazy((_, { parent }) => {
    const rules = parent.supported_languages.reduce((rules, lang) => {
      rules[lang] = Yup.object({
        common: Yup.object({
          words: Yup.object({
            item: Yup.string().nullable().required('Text is required'),
          }),
        }),
        fields: Yup.object({
          feedback: Yup.object({
            label: Yup.string().nullable().required('Text is required'),
          }),
          request_type: Yup.object({
            label: Yup.string().nullable().required('Text is required'),
          }),
          machine_type: Yup.object({
            label: Yup.string().nullable().required('Text is required'),
          }),
          description: Yup.object({
            label: Yup.string().nullable().required('Text is required'),
            validations: Yup.object({
              required: Yup.string().nullable().required('Text is required'),
            }),
          }),
          machine_id: Yup.object({
            label: Yup.string().nullable().required('Text is required'),
            aside: Yup.object({
              required_text: Yup.string().nullable().required('Text is required'),
              optional_text: Yup.string().nullable().required('Text is required'),
            }),
          }),
          machine_location: Yup.object({
            label: Yup.string().nullable().required('Text is required'),
          }),
          refund_address: Yup.object({
            aside: Yup.string().nullable().optional(),
          }),
          photo: Yup.object({
            label: Yup.string().nullable().required('Text is required'),
          }),
          refund_requested: Yup.object({
            label: Yup.string().nullable().required('Text is required'),
            text: Yup.string().nullable().required('Text is required'),
          }),
        }),
        steps: Yup.object({
          selector: Yup.object({
            with_tag_title: Yup.string().nullable().required('Text is required'),
            without_tag_title: Yup.string().nullable().required('Text is required'),
          }),
          rate: Yup.object({
            title: Yup.string().nullable().required('Text is required'),
          }),
          contact: Yup.object({
            title: Yup.string().nullable().required('Text is required'),
          }),
          auth_hold_info: Yup.object({
            title: Yup.string().nullable().required('Text is required'),
            text: Yup.string().nullable().required('Text is required'),
            faqs: Yup.object({
              title_1: Yup.string().nullable().required('Text is required'),
              text_1: Yup.string().nullable().required('Text is required'),
              title_2: Yup.string().nullable().required('Text is required'),
              text_2: Yup.string().nullable().required('Text is required'),
            }),
          }),
          success: Yup.object({
            title: Yup.string().nullable().required('Text is required'),
            text: Yup.string().nullable().required('Text is required'),
            customer_blocked: Yup.object({
              text: Yup.string().nullable().when({
                is: () => parent.settings['content_customer_show-blocked-message'],
                then: (schema) => schema.required('Text is required'),
              }),
            }),
          }),
        }),
        sms: Yup.object({
          voice_message: Yup.object({
            received: Yup.string().nullable().when({
              is: () => parent.settings['request_sms_send-voice-message-received'],
              then: (schema) => schema.required('Text is required'),
            }),
          }),
          help: Yup.object({
            received: Yup.string().nullable().when({
              is: () => parent.settings['request_sms_send-help-request-received'],
              then: (schema) => schema.required('Text is required'),
            }),
            closed: Yup.string().nullable().when({
              is: () => parent.settings['request_sms_send-help-request-closed'],
              then: (schema) => schema.required('Text is required'),
            }),
            request_link: Yup.string().nullable().required('Text is required'),
          }),
          feedback: Yup.object({
            received: Yup.string().nullable().when({
              is: () => parent.settings['request_sms_send-feedback-request-received'],
              then: (schema) => schema.required('Text is required'),
            }),
          }),
          refund: Yup.object({
            request_link: Yup.string().nullable().required('Text is required'),
            delayed_follow_up: Yup.string().nullable().required('Text is required'),
            instant_follow_up: Yup.string().nullable().required('Text is required'),
            received: Yup.string().nullable().when({
              is: () => parent.settings['request_sms_send-refund-details-received'],
              then: (schema) => schema.required('Text is required'),
            }),
            approved_online_method: Yup.string().nullable().when({
              is: () => parent.settings['request_sms_send-refund-approved'],
              then: (schema) => schema.required('Text is required'),
            }),
            approved_cash_method: Yup.string().nullable().when({
              is: () => parent.settings['request_sms_send-refund-approved'],
              then: (schema) => schema.required('Text is required'),
            }),
            approved_reversal_method: Yup.string().nullable().when({
              is: () => parent.settings['request_sms_send-refund-approved'],
              then: (schema) => schema.required('Text is required'),
            }),
            declined: Yup.string().nullable().when({
              is: () => parent.settings['request_sms_send-refund-declined'],
              then: (schema) => schema.required('Text is required'),
            }),
          }),
        })
      });

      return rules;
    }, {});

    return Yup.object(rules).required();
  }),
  supported_languages: Yup.array().min(1, 'Select at least one language'),
  sms_languages: Yup.array().min(1, 'Select at least one language').test({
    name: 'sms_languages_valid',
    test: (item, ctx) => {
      const diff = difference(item, ctx.parent.supported_languages);

      if (diff.length) {
        const language = {
          'en': 'English',
          'es': 'Español',
          'fr': 'French',
        };
        const languageText = diff.map(v => language[v]).join(', ');
        return ctx.createError({ message: `${languageText} is missing from the Supported Languages list` })
      }

      return true;
    }
  }),
  settings: Yup.object({
    'content_url_about-us': Yup.string().nullable().url('Enter a valid URL.').optional(),
    'content_url_terms-of-use': Yup.string().nullable().url('Enter a valid URL.').optional(),
    'content_url_privacy-policy': Yup.string().nullable().url('Enter a valid URL.').optional(),
    'content_tag_details-format': Yup.string().required('Text is required'),
    'content_tag_details-hidden': Yup.bool(),
    'content_customer_show-blocked_message': Yup.bool(),
    'request_sms_send-voice-message-received': Yup.bool(),
    'request_sms_send-help-request-received': Yup.bool(),
    'request_sms_send-help-request-closed': Yup.bool(),
    'request_sms_send-feedback-request-received': Yup.bool(),
    'request_sms_send-refund-details-received': Yup.bool(),
    'request_sms_send-refund-approved': Yup.bool(),
    'request_sms_send-refund-declined': Yup.bool(),
    'feedback_disabled': Yup.bool(),
  }),
});
