import { CollapsableCard } from '@components/ui';
import { IRefundRecord } from '@interfaces';
import dayjs from 'dayjs';
import { IconCancel, IconCalendarTime, IconSend, IconLink } from '@tabler/icons-react';
import { Button, Group, Popover, TextInput, Text } from '@mantine/core';
import { useSendPreAuthFollowUpSMS } from '@components/hooks';
import { useDisclosure } from '@mantine/hooks';
import { useCancelPreAuthFollowUpSMS } from '@components/hooks/useCancelPreAuthFollowUpSMS';
import { useCreatePreAuthFollowUpLink } from '@components/hooks/useCreatePreAuthFollowUpLink';

type Props = {
  refund: IRefundRecord;
}

export const PreAuthFollowUpCard = ({ refund }: Props) => {
  const { copyLink, isLoading: linkIsLoading } = useCreatePreAuthFollowUpLink();
  const { send, isLoading: sendIsLoading } = useSendPreAuthFollowUpSMS();
  const { cancel, isLoading: cancelIsLoading } = useCancelPreAuthFollowUpSMS();
  const [sendOpened, sendHandlers] = useDisclosure(false);
  const [cancelOpened, cancelHandlers] = useDisclosure(false);

  const handleSendTrigger = () => {
    sendHandlers.close();
    send(refund.id);
  };

  const handleCancelTrigger = () => {
    cancelHandlers.close();
    cancel(refund.id);
  };

  if (
    !refund.meta.follow_up_scheduled_at &&
    !refund.meta.follow_up_sent_at &&
    !refund.meta.follow_up_cancelled_at
  ) {
    return;
  }

  return <CollapsableCard label="Pre-auth hold ends" collapsable={false}>
    { !refund.meta.follow_up_sent_at && !refund.meta.follow_up_cancelled_at && <>
      <Group wrap="nowrap" align="start" gap="sm">
        <TextInput flex={1}
                   description="Follow up notification scheduled to be sent"
                   value={dayjs(refund.meta.follow_up_scheduled_at).format('D MMM \'YY h:mma')}
                   readOnly
                   leftSection={<IconCalendarTime />} />

        <Popover opened={sendOpened} onChange={sendHandlers.close} position="bottom" withArrow shadow="md" offset={4}>
          <Popover.Target>
            <Button variant="filled"
                    px="xs"
                    leftSection={<IconSend size={16}/>}
                    loading={sendIsLoading}
                    onClick={sendHandlers.open}>Send now</Button>
          </Popover.Target>
          <Popover.Dropdown p="xs">
            <Group wrap="nowrap" gap="xs">
              <Button variant="default" onClick={sendHandlers.close}>Cancel</Button>
              <Button variant="outline" onClick={handleSendTrigger}>Confirm</Button>
            </Group>
          </Popover.Dropdown>
        </Popover>

        <Popover opened={cancelOpened} onChange={cancelHandlers.close} position="bottom" withArrow shadow="md" offset={4}>
          <Popover.Target>
            <Button variant="filled"
                    color="red"
                    px="xs"
                    leftSection={<IconCancel size={16}/>}
                    loading={cancelIsLoading}
                    onClick={cancelHandlers.open}>Cancel</Button>
          </Popover.Target>
          <Popover.Dropdown p="xs">
            <Group wrap="nowrap" gap="xs">
              <Button variant="default" onClick={cancelHandlers.close}>Cancel</Button>
              <Button variant="outline" onClick={handleCancelTrigger}>Confirm</Button>
            </Group>
          </Popover.Dropdown>
        </Popover>
      </Group>
    </>}

    { refund.meta.follow_up_sent_at && <Group wrap="nowrap" align="start" gap="sm">
      <TextInput flex={1}
                 description="Follow up notification message sent"
                 value={dayjs(refund.meta.follow_up_sent_at).format('D MMM \'YY h:mma')}
                 readOnly
                 leftSection={<IconCalendarTime />} />

      <Button variant="outline"
              px="xs"
              rightSection={<IconLink size={16}/>}
              loading={linkIsLoading}
              onClick={() => copyLink(refund.id)}>Get Link</Button>
    </Group>}

    { refund.meta.follow_up_cancelled_at && <>
      <TextInput description="Follow up notification message cancelled"
                 value={dayjs(refund.meta.follow_up_cancelled_at).format('D MMM \'YY h:mma')}
                 readOnly
                 leftSection={<IconCalendarTime />} />
    </>}
  </CollapsableCard>;
}
