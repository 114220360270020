import { Divider, Input, Select, SimpleGrid, Stack, Switch, TextInput } from '@mantine/core';
import type { UseFormReturnType } from '@mantine/form/lib/types';
import { ICustomerRecord } from '@interfaces';
import switchClasses from '@components/styles/components/Switch.module.css';
import { Feature } from 'flagged';
import { useId } from '@mantine/hooks';
import { EditableInput } from '@components/ui/form/EditableInput';
import { IconCheck } from '@tabler/icons-react';

interface Props {
  form: UseFormReturnType<Partial<ICustomerRecord>>;
}

export const CustomerFormFields = ({ form }: Props) => {
  // Using a random ID with the field ID's due to this card possibly appearing multiple times.
  const fieldId = useId();

  const isNew = !form.values.id;

  return <>
    <Stack>
      <SimpleGrid cols={{ md: 2 }} spacing="xs">
        { isNew && <>
          <TextInput label="Name" {...form.getInputProps('name')} />
          <TextInput label="Email (optional)" {...form.getInputProps('email')} />
          <TextInput label="Mobile (optional)" {...form.getInputProps('phone_number')} />
        </>}

        { !isNew && <>
          <EditableInput label="Name" form={form} field="name" />
          <EditableInput label="Email" form={form} field="email" />
          <EditableInput label="Mobile" form={form} field="phone_number"
                         leftSection={form.values.confirmed_at ? <IconCheck strokeWidth={1.5} /> : null}
          />
        </>}

        <Select label="Language"
                data={[
                  { value: 'en', label: 'English' },
                  { value: 'es', label: 'Español' },
                  { value: 'fr', label: 'French' },
                ]}
                {...form.getInputProps('locale')}
        />
      </SimpleGrid>
    </Stack>

    <Divider my="md" />

    <Stack gap={5} mt="xs">
      <Input.Wrapper id={`exclude_feedback_${fieldId}`} label="Exclude from feedback automation" className={switchClasses.inline}>
        <Switch id={`exclude_feedback_${fieldId}`}
                offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.feedback_exclude_automation', { type: 'checkbox' })}
        />
      </Input.Wrapper>

      <Feature name="refunds.has_automation">
        <Input.Wrapper id={`exclude_refunds_${fieldId}`} label="Exclude from refund automation" className={switchClasses.inline}>
          <Switch id={`exclude_refunds_${fieldId}`}
                  offLabel="No" onLabel="Yes" size="md"
                  {...form.getInputProps('settings.refunds_exclude_automation', { type: 'checkbox' })}
          />
        </Input.Wrapper>
      </Feature>

      <Input.Wrapper id={`unsubscribed_${fieldId}`} label="Customer is unsubscribed" className={switchClasses.inline}>
        <Switch id={`unsubscribed_${fieldId}`}
                offLabel="No" onLabel="Yes" size="md"
                {...form.getInputProps('settings.notifications_unsubscribed', { type: 'checkbox' })}
        />
      </Input.Wrapper>

      <Input.Wrapper id={`marked_status_${fieldId}`} label="Mark customer as" className={switchClasses.inline}>
        <Select
          size="xs" w={100}
          data={[
            { value: 'None', label: 'None' },
            { value: 'Priority', label: 'Priority' },
            { value: 'Flagged', label: 'Flagged' },
            { value: 'Blocked', label: 'Blocked' },
          ]}
          {...form.getInputProps('marked_status')}
        />
      </Input.Wrapper>
    </Stack>
  </>;
}
