import { Text } from '@mantine/core';
import dayjs from 'dayjs';

interface Props {
  value?: string;
}

export const DateTime = ({ value }: Props) => {
  if (!value) return <Text c="dimmed">&mdash;</Text>;

  const datetime = dayjs(value);

  return <>{datetime.format('D MMM \'YY h:mma')}</>
}
