import { useEffect } from 'react';
import { useApiUrl, useCustomMutation } from '@refinedev/core';
import {
  TextInput,
  Button,
  Divider,
  Group,
  Loader,
  NumberInput,
  PasswordInput,
  Select,
  SimpleGrid,
  Stack,
  Switch,
  Title,
  Card
} from '@mantine/core';
import { IFilterOptionItem, IIntegrationRecord, IIntegrationVendSysRecord } from '@interfaces';
import { useConnectionGuard } from './useConnectionGuard';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';

interface Props {
  record?: IIntegrationVendSysRecord;
}

const schema = Yup.object().shape({
  credentials: Yup.object().required().shape({
    api_key: Yup.string().required('Enter an api key'),
    api_token: Yup.string().required('Enter a token'),
  }),
  is_active: Yup.bool(),
  settings: Yup.object().when('platform', ([_platform], schema) => {
    return schema.shape({
      'vendsys_allow-service-calls': Yup.bool().required(),
      'automation_vendsys_create-service-calls': Yup.bool().required(),
      'automation_vendsys_sync-equipment': Yup.bool().required(),
      'automation_vendsys_complaintId': Yup.mixed().nullable().when('vendsys_allow-service-calls', {
        is: true,
        then: (schema) => schema.required('Select a complaint type'),
      }),
      'automation_vendsys_technicianId': Yup.mixed().nullable().when('vendsys_allow-service-calls', {
        is: true,
        then: (schema) => schema.nullable().optional(),
      }),
      'automation_vendsys_default-asset-number': Yup.mixed().nullable().when('vendsys_allow-service-calls', {
        is: true,
        then: (schema) => schema.nullable().optional(),
      }),
      'automation_vendsys_priority': Yup.number().transform(v => isNaN(v) ? undefined : v).when('vendsys_allow-service-calls', {
        is: true,
        then: (schema) => schema.min(1, 'Must be 1 or higher').max(10000, 'Must be lower than 10001').required('Enter a priority, 1 - 10000'),
      }),
    })
  })
});

export const VendSysDetails = ({ record }: Props) => {
  const { form: parentForm } = useResourceForm<IIntegrationRecord>();
  const apiUrl = useApiUrl();
  const { mutate: getComplaintTypes, data: complaintTypes, isLoading: isComplaintTypesLoading } = useCustomMutation<IFilterOptionItem[]>();
  const { mutate: getTechnicians, data: technicians, isLoading: isTechniciansLoading } = useCustomMutation<IFilterOptionItem[]>();
  const { checkConnection, isCheckingConnection, hasConnection } = useConnectionGuard();

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      is_active: record?.is_active ?? true,
      credentials: {
        api_key: record?.credentials.api_key ?? '',
        api_token: record?.credentials.api_token ?? '',
      },
      settings: {
        'vendsys_allow-service-calls': record?.settings['vendsys_allow-service-calls'] ?? false,
        'automation_vendsys_create-service-calls': record?.settings['automation_vendsys_create-service-calls'] ?? false,
        'automation_vendsys_sync-equipment': record?.settings['automation_vendsys_sync-equipment'] ?? false,
        'automation_vendsys_complaintId': record?.settings['automation_vendsys_complaintId']?.toString() ?? '',
        'automation_vendsys_technicianId': record?.settings['automation_vendsys_technicianId']?.toString() ?? '',
        'automation_vendsys_default-asset-number': record?.settings['automation_vendsys_default-asset-number'] ?? '',
        'automation_vendsys_priority': record?.settings['automation_vendsys_priority'] ?? 200,
      }
    },
    transformValues: (values) => {
      if (values.settings['automation_vendsys_complaintId']) {
        values.settings['automation_vendsys_complaintId'] = Number(values.settings['automation_vendsys_complaintId']);
      }

      if (values.settings['automation_vendsys_technicianId']) {
        values.settings['automation_vendsys_technicianId'] = Number(values.settings['automation_vendsys_technicianId']);
      }

      values.owner_id = parentForm.values.owner_id;
      values.platform = parentForm.values.platform;

      return values;
    }
  });

  useEffect(() => {
    if (hasConnection && form.values.settings['vendsys_allow-service-calls']) {
      const payload = record?.id ? { integration_id: record.id } : form.values;

      getComplaintTypes({
        url: `${apiUrl}/integration/vms/options/complaint_types`, method: 'post', values: payload,
      });

      getTechnicians({
        url: `${apiUrl}/integration/vms/options/technicians`, method: 'post', values: payload,
      });
    }
  }, [hasConnection, form.values.settings['vendsys_allow-service-calls']]);

  return <ResourceForm form={form} showBackButton={false} mx={0}>
    <Stack>
      <PasswordInput label="API Key" {...form.getInputProps('credentials.api_key')} defaultVisible={!record} />

      <PasswordInput label="Company ID" {...form.getInputProps('credentials.api_token')} defaultVisible={!record} />

      <Group>
        <Button variant="outline" loading={isCheckingConnection}
                disabled={!form.isDirty () && (hasConnection || !form.values.credentials.api_key || !form.values.credentials.api_token)}
                onClick={() => checkConnection(form.values)}>
          Check Connection
        </Button>
      </Group>

      { hasConnection && <>
        <Divider my="xs" />

        <Title order={5} mb={0}>Settings</Title>

        <SimpleGrid cols={{ md: 2 }} spacing="lg">
          <Switch label="Enable service ticket support"
                  description="Contact VendSys for details."
                  offLabel="No" onLabel="Yes"
                  {...form.getInputProps('settings.vendsys_allow-service-calls', { type: 'checkbox' })}
          />
        </SimpleGrid>

        { form.values.settings['vendsys_allow-service-calls'] && <>
          <Divider />

          <Select
            label="Select complaint type"
            description="Used to set the complaint type of the service call"
            placeholder="Select a type..."
            leftSection={isComplaintTypesLoading && <Loader size="xs" />}
            data={complaintTypes?.data || []}
            { ...form.getInputProps('settings.automation_vendsys_complaintId') }
          />

          <SimpleGrid cols={{ md: 2 }} spacing="lg">
            <Select
              label="Select a technician"
              description="Used to optionally assign a service call to a technician"
              placeholder="Select a technician..."
              leftSection={isTechniciansLoading && <Loader size="xs" />}
              data={technicians?.data || []}
              { ...form.getInputProps('settings.automation_vendsys_technicianId') }
            />

            <NumberInput label="Priority"
                         description="Sets the priority of the service call (1 - 10000)"
                         min={1}
                         max={10000}
                         {...form.getInputProps('settings.automation_vendsys_priority')} />
          </SimpleGrid>

          <TextInput label="Default asset number"
                     placeholder="None"
                     description={<a href="http://docs.zippyassist.com/en/articles/7910854-vendsys-integration#h_bc7f63eec3" target="_blank">For more info click here.</a>}
                     {...form.getInputProps('settings.automation_vendsys_default-asset-number')} />
        </>}

        <Divider my="xs" />

        <Title order={5} mb={0}>Automations</Title>

        <SimpleGrid cols={{ md: 2 }} spacing="lg">
          <Switch label="Sync equipment"
                  description="Automatically keeps imported equipment data up to date"
                  offLabel="No" onLabel="Yes"
                  {...form.getInputProps('settings.automation_vendsys_sync-equipment', { type: 'checkbox' })}
          />

          { form.values.settings['vendsys_allow-service-calls'] && <>
            <Switch label="Create service tickets"
                    description="When a help request is received, a service call is automatically created"
                    offLabel="No" onLabel="Yes"
                    {...form.getInputProps('settings.automation_vendsys_create-service-calls', { type: 'checkbox' })}
            />
          </>}
        </SimpleGrid>
      </>}

      <Divider my="xs" />

      <Switch label="Enabled"
              description="Marks the integration as Active or Inactive"
              offLabel="No" onLabel="Yes"
              { ...form.getInputProps('is_active', { type: 'checkbox' }) }
      />

      <Card.Section withBorder px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Stack>
  </ResourceForm>;
}
