import { createContext, useContext } from 'react';

type TableConfigurationContextProps = {
  initialPageSize: number;
};

export const TableConfigurationContext = createContext<TableConfigurationContextProps>({
  initialPageSize: 15
});

export function useTableConfiguration() {
  const context = useContext(TableConfigurationContext);

  if (!context) {
    throw new Error('useTableConfiguration hook was called outside of TableConfigurationProvider context');
  }

  return context;
}
