import { Text } from '@mantine/core';
import { IVoiceMessageSettingListItem, IVoiceMessageSettingRecord } from '@interfaces';
import { TableRow } from '@components/table/TableRow';
import { Tabs } from '@components/ui/tabs/Tabs';
import { VoiceMessageHandlerForm } from '../form/VoiceMessageHandlerForm';
import { VoiceMessageScheduleForm } from '../form/VoiceMessageScheduleForm';
import { VoiceMessageSettingsForm } from '../form/VoiceMessageSettingsForm';
import { Point } from '@components/ui/Point';

type Props = {
  item: IVoiceMessageSettingListItem;
}

type TabContentProps = {
  record: IVoiceMessageSettingRecord;
}

const TabContent = ({ record }: TabContentProps) => {
  return <VoiceMessageSettingsForm record={record}>
    <Tabs variant="outline" defaultValue={record.type.toLowerCase()}>
      <Tabs.List>
        <Tabs.Tab value="active">
          <Text size="sm" fw="500">Setting A</Text>
        </Tabs.Tab>
        <Tabs.Tab value="inactive">
          <Text size="sm" fw="500">Setting B</Text>
        </Tabs.Tab>
        <Tabs.Tab value="schedule">
          <Text size="sm" fw="500">Schedule</Text>
        </Tabs.Tab>
      </Tabs.List>

      <Tabs.Panel value="active" pt="xs">
        <VoiceMessageHandlerForm type="active_settings" />
      </Tabs.Panel>

      <Tabs.Panel value="inactive" pt="xs">
        <VoiceMessageHandlerForm type="inactive_settings" />
      </Tabs.Panel>

      <Tabs.Panel value="schedule" pt="xs">
        <VoiceMessageScheduleForm />
      </Tabs.Panel>
    </Tabs>
  </VoiceMessageSettingsForm>;
}

export default ({ item }: Props) => {
  return <TableRow<IVoiceMessageSettingRecord> resource="voice_message/settings" id={item.id}>
    {(record) => <TabContent record={record} />}
  </TableRow>;
}
