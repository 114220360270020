import { Box, Button, Divider, Grid, Group, List, Select, Stack, Text, Title } from '@mantine/core';
import { getCountryTimezone } from '@components/ui/timezones';
import { useIdentity } from '@components/data/Identity.context';
import { Scheduler } from './Scheduler';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IVoiceMessageSettingRecord } from '@interfaces';
import { StatusSelect } from '@components/ui/form/StatusSelect';
import { CallOut } from '@components/ui/CallOut';

export const VoiceMessageScheduleForm = () => {
  const { form } = useResourceForm<IVoiceMessageSettingRecord>();
  const { identity } = useIdentity();

  return <Grid>
    <Grid.Col span={{ lg: 6, md: 7 }}>
      <Stack>
        <Stack gap={2}>
          <Title order={6}>Default Schedule Setting</Title>
          <Text size="sm">Select the default setting for when the schedule is in use.</Text>

          <Select mt={5}
                  w={125}
                  data={[
                    {value: 'Active', label: 'Setting A'},
                    {value: 'Inactive', label: 'Setting B'},
                  ]}
                  {...form.getInputProps(`schedule_settings.type`)}
          />
        </Stack>

        <Stack gap={2}>
          <Title order={6}>Schedule</Title>
          <Text size="sm">Set specific times to override the default setting.</Text>

          <Select
            mt={5}
            w={325}
            searchable
            nothingFoundMessage="Nothing found"
            data={getCountryTimezone(identity?.owner.country_code) as any}
            {...form.getInputProps(`schedule_settings.timezone`)}
          />
        </Stack>

        <Scheduler />

        <Divider />

        <Group justify="right">
          <Button variant="light" color="gray" onClick={() => form.reset()}>Cancel</Button>
          <ResourceForm.UpdateButton />
        </Group>
      </Stack>
    </Grid.Col>
    <Grid.Col span={{ lg: 6, md: 5 }}>
      <CallOut>
        <Stack gap="sm">
          <Text size="sm">This schedule will be used to determine how calls are answered when scheduling is active.</Text>

          <Box>
            <Title order={6} mb={2}>How Should I Configure These Settings?</Title>
            <List type="ordered" size="sm" listStyleType="decimal" ml="xs">
              <List.Item>Choose a default action in the "Default Schedule Setting", then;</List.Item>
              <List.Item>Add only times to the Schedule section when you want to use a different setting to the default.</List.Item>
            </List>
          </Box>

          <Text size="sm">For example, if you want to have Voice Messages use "Setting A" at all times EXCEPT Mondays from 9am to 12pm midday, set Default Schedule Setting to "Setting A" then add an entry for Monday 9am to 12pm to change the setting to "Setting B" during that time. At all other times, Voice Messages will be use "Setting A".</Text>

          <Text size="sm">NOTE: the settings on this "Schedule" tab only apply when the phone number is set to "Schedule".</Text>

          <Box>
            <Title order={6} mb={2}>Default Schedule Setting</Title>
            <Text size="sm">When the "Schedule" setting is used, this setting determines what setting Voice Messages will use by default.</Text>
            <Text size="sm">To change when the default setting is used, enter times in the Schedule section.</Text>
          </Box>

          <Box>
            <Title order={6} mb={2}>Schedule</Title>
            <Text size="sm">Enter times to use a setting other than the default.</Text>
          </Box>
        </Stack>
      </CallOut>
    </Grid.Col>
  </Grid>;
}
