import { ActionIcon, Group, Input, Select, Stack, Text, useMantineTheme } from '@mantine/core';
import React from 'react';
import { filterAvailableToTimeOptions, filterAvailableFromTimeOptions } from './default_time_options';
import { IconTrash, IconCirclePlus } from '@tabler/icons-react';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { IVoiceMessageSettingRecord } from '@interfaces';
import { StatusSelect } from '@components/ui/form/StatusSelect';
import { useMediaQuery } from '@mantine/hooks';

export const Scheduler = () => {
  const { form } = useResourceForm<IVoiceMessageSettingRecord>();
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`, false);

  // Keeps the days of the week in order,
  const schedule = {
    mon: form.values.schedule_settings.schedule.mon,
    tue: form.values.schedule_settings.schedule.tue,
    wed: form.values.schedule_settings.schedule.wed,
    thu: form.values.schedule_settings.schedule.thu,
    fri: form.values.schedule_settings.schedule.fri,
    sat: form.values.schedule_settings.schedule.sat,
    sun: form.values.schedule_settings.schedule.sun,
  };

  const getError = (key: string) => form.errors[`schedule_settings.schedule.${key}`];

  return <Stack>
    { Object.entries(schedule).map(([day, slots]) => <Group key={day} align="top" wrap="nowrap">
      <Text fw={500} w={32} mt={slots.length ? 8 : 2} tt="capitalize" style={{ whiteSpace: 'nowrap' }}>{day}</Text>

      <Stack gap={isMobile ? 8 : 5}>
        { slots.map((slot, index) => <Stack key={index} gap={0}>
          <Group gap={isMobile ? 5 : 8} align="top">
            <Group gap={8} align="top" grow>
              <Select data={filterAvailableFromTimeOptions(slots, index)} maw={ isMobile ? '100%' : 110} flex={1}
                      {...form.getInputProps(`schedule_settings.schedule.${day}.${index}.from`)}
                      error={!!getError(`${day}.${index}`)}
              />
              <Text mt={8} flex={0}>–</Text>
              <Select data={filterAvailableToTimeOptions(slot)} maw={ isMobile ? '100%' : 110} flex={1}
                      {...form.getInputProps(`schedule_settings.schedule.${day}.${index}.to`)}
                      disabled={!form.values?.schedule_settings?.schedule?.[day]?.[index]?.from}
                      error={!!getError(`${day}.${index}`)}
              />
            </Group>

            <Select data={[
                {value: 'Active', label: 'Setting A'},
                {value: 'Inactive', label: 'Setting B'},
              ]}
              w={120}
              {...form.getInputProps(`schedule_settings.schedule.${day}.${index}.type`)}
            />

            <ActionIcon variant="default" mt={4}
                        onClick={() => form.insertListItem(`schedule_settings.schedule.${day}`, { from: null, to: null, type: 'Active' }, index + 1)}>
              <IconCirclePlus size={16} stroke={1.5} />
            </ActionIcon>
            <ActionIcon variant="default" mt={4}
                        onClick={() => form.removeListItem(`schedule_settings.schedule.${day}`, index)}>
              <IconTrash size={16} stroke={1.5} />
            </ActionIcon>
          </Group>

          { getError(`${day}.${index}`) && <Group>
            <Input.Error>{ getError(`${day}.${index}`) }</Input.Error>
          </Group> }
        </Stack>)}

        { slots.length === 0 && <ActionIcon variant="default"
                    onClick={() => form.insertListItem(`schedule_settings.schedule.${day}`, { from: '', to: '', type: 'Active' })}>
          <IconCirclePlus size={16} stroke={1.5} />
        </ActionIcon>}
      </Stack>
    </Group>)}
  </Stack>;
}
