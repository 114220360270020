// See https://www.twilio.com/docs/voice/twiml/say/text-speech#available-voices-and-languages
// Language (Locale), ID, Type, Gender, Voice name
export default [
  {language_locale: 'English / US', language_id: 'en-US', type: 'Basic', gender: 'Male', voice_name: 'man', display_name: 'Man'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Basic', gender: 'Female', voice_name: 'woman', display_name: 'Woman'},
  // {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Polly.Ivy', display_name: 'Ivy'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Polly.Joanna', display_name: 'Joanna'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Male', voice_name: 'Polly.Joey', display_name: 'Joey'},
  // {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Male', voice_name: 'Polly.Justin', display_name: 'Justin'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Polly.Kendra', display_name: 'Kendra'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Polly.Kimberly', display_name: 'Kimberly'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Male', voice_name: 'Polly.Matthew', display_name: 'Matthew'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Polly.Salli', display_name: 'Salli'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Male', voice_name: 'Google.en-US-Standard-A', display_name: 'Google Voice A'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Male', voice_name: 'Google.en-US-Standard-B', display_name: 'Google Voice B'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Google.en-US-Standard-C', display_name: 'Google Voice C'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Male', voice_name: 'Google.en-US-Standard-D', display_name: 'Google Voice D'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Google.en-US-Standard-E', display_name: 'Google Voice E'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Google.en-US-Standard-F', display_name: 'Google Voice F'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Google.en-US-Standard-G', display_name: 'Google Voice G'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Female', voice_name: 'Google.en-US-Standard-H', display_name: 'Google Voice H'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Male', voice_name: 'Google.en-US-Standard-I', display_name: 'Google Voice I'},
  {language_locale: 'English / US', language_id: 'en-US', type: 'Standard', gender: 'Male', voice_name: 'Google.en-US-Standard-J', display_name: 'Google Voice J'},
  {language_locale: 'Spanish / US', language_id: 'es-US', type: 'Basic', gender: 'Male', voice_name: 'Man', display_name: 'Man'},
  {language_locale: 'Spanish / US', language_id: 'es-US', type: 'Basic', gender: 'Female', voice_name: 'Woman', display_name: 'Woman'},
  {language_locale: 'Spanish / US', language_id: 'es-US', type: 'Standard', gender: 'Female', voice_name: 'Polly.Lupe', display_name: 'Lupe'},
  {language_locale: 'Spanish / US', language_id: 'es-US', type: 'Standard', gender: 'Male', voice_name: 'Polly.Miguel', display_name: 'Miguel'},
  {language_locale: 'Spanish / US', language_id: 'es-US', type: 'Standard', gender: 'Female', voice_name: 'Polly.Penelope', display_name: 'Penelope'},
  {language_locale: 'Spanish / US', language_id: 'es-US', type: 'Standard', gender: 'Female', voice_name: 'Google.es-US-Standard-A', display_name: 'Google Voice A'},
  {language_locale: 'Spanish / US', language_id: 'es-US', type: 'Standard', gender: 'Male', voice_name: 'Google.es-US-Standard-B', display_name: 'Google Voice B'},
  {language_locale: 'Spanish / US', language_id: 'es-US', type: 'Standard', gender: 'Male', voice_name: 'Google.es-US-Standard-C', display_name: 'Google Voice C'},
  {language_locale: 'French / Canada', language_id: 'fr-CA', type: 'Standard', gender: 'Female', voice_name: 'Polly.Chantal', display_name: 'Chantal'},
  {language_locale: 'French / Canada', language_id: 'fr-CA', type: 'Standard', gender: 'Female', voice_name: 'Google.fr-CA-Standard-A', display_name: 'Google Voice A'},
  {language_locale: 'French / Canada', language_id: 'fr-CA', type: 'Standard', gender: 'Male', voice_name: 'Google.fr-CA-Standard-B', display_name: 'Google Voice B'},
  {language_locale: 'French / Canada', language_id: 'fr-CA', type: 'Standard', gender: 'Female', voice_name: 'Google.fr-CA-Standard-C', display_name: 'Google Voice C'},
  {language_locale: 'French / Canada', language_id: 'fr-CA', type: 'Standard', gender: 'Male', voice_name: 'Google.fr-CA-Standard-D', display_name: 'Google Voice D'},
];
