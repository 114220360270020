import { IVoiceMessageSettingListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';

import VoiceMessageSettingTableColumns from './VoiceMessageSettingTableColumns';
import VoiceMessageSettingTableExpandedRow from './VoiceMessageSettingTableExpandedRow';

export const VoiceMessageSettingTable = (props: ResourceTableProps<IVoiceMessageSettingListItem>) => {
  return <ResourceTable<IVoiceMessageSettingListItem>
    resource="voice_message/settings"
    columns={VoiceMessageSettingTableColumns}
    onRenderExpanded={VoiceMessageSettingTableExpandedRow}
    excludeColumns={['id']}
    allowExports={false}
    {...props}
  />;
}
