import { ActionIcon, Alert, Box, Group, Input, LoadingOverlay, SimpleGrid, Stack, Text } from '@mantine/core';
import { EquipmentSelectorPopover } from '@components/ui/EquipmentSelectorPopover';
import { IconTrash } from '@tabler/icons-react';
import { CollapsableCard } from '@components/ui';
import { TagDetails } from '@components/data/TagDetails';
import { useRequestState } from '@components/ui/drawers/Requests/RequestState.context';
import { useDidUpdate } from '@mantine/hooks';
import { useOne } from '@refinedev/core';
import { useState } from 'react';
import { ITagListItem, ITagRecord } from '@interfaces';

export const TagSelector = () => {
  const { setTag, setIsTagLoading } = useRequestState();
  const [selectedTag, setSelectedTag] = useState<ITagListItem>();

  const { data: tag, isFetching: isTagLoading, refetch } = useOne<ITagRecord>({
    resource: 'tag', id: selectedTag?.id, queryOptions: { enabled: false }
  });

  useDidUpdate(() => {
    setIsTagLoading(isTagLoading);
  }, [isTagLoading]);

  useDidUpdate(() => {
    refetch();
  }, [selectedTag]);

  useDidUpdate(() => {
    if (tag) {
      setTag(tag.data);
    }
  }, [tag]);

  return <Input.Wrapper label="Equipment (optional)">
    <EquipmentSelectorPopover onChange={setSelectedTag} />
  </Input.Wrapper>
}

export const TagAlert = () => {
  const { tag, setTag } = useRequestState();

  if (!tag) {
    return;
  }

  return <Alert variant="light" p="xs">
    <Group justify="space-between">
      <Stack gap={2}>
        <Text>(ID: {tag.identifier}) {tag.name}</Text>
        { tag.account_name && <Text size="xs">{ tag.account_name }</Text>}
        { tag.address && <Text size="xs">{ tag.address.formatted_address }</Text>}
      </Stack>

      <ActionIcon variant="subtle" onClick={() => setTag(null)}>
        <IconTrash size={16} />
      </ActionIcon>
    </Group>
  </Alert>;
}

export const TagDetailsCard = () => {
  const { tag, isTagLoading } = useRequestState();

  if (!tag) {
    return;
  }

  return <Box pos="relative">
    <LoadingOverlay visible={isTagLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />
    <CollapsableCard label="Equipment information">
      <SimpleGrid spacing="sm">
        <TagDetails tag={tag} />
      </SimpleGrid>
    </CollapsableCard>
  </Box>;
}
