import { Button, Divider, Group, PasswordInput, SimpleGrid, Stack, Switch, Title, Card } from '@mantine/core';
import { IIntegrationRecord, IIntegrationSeedRecord } from '@interfaces';
import { useConnectionGuard } from './useConnectionGuard';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { ResourceForm } from '@components/ui/form/ResourceForm';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';

interface Props {
  record?: IIntegrationSeedRecord;
}

const schema = Yup.object().shape({
  credentials: Yup.object().required().shape({
    api_key: Yup.string().required('Enter a username'),
    api_token: Yup.string().required('Enter a password'),
  }),
  is_active: Yup.bool(),
  settings: Yup.object().when('platform', ([_platform], schema) => {
    return schema.shape({
      'automation_vendsys_sync-equipment': Yup.bool().required(),
    })
  })
});

export const SeedDetails = ({ record }: Props) => {
  const { form: parentForm } = useResourceForm<IIntegrationRecord>();
  const { checkConnection, isCheckingConnection, hasConnection } = useConnectionGuard();

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      is_active: record?.is_active ?? true,
      credentials: {
        api_key: record?.credentials.api_key ?? '',
        api_token: record?.credentials.api_token ?? '',
      },
      settings: {
        'automation_vendsys_sync-equipment': record?.settings['automation_vendsys_sync-equipment'] ?? false,
      }
    },
    transformValues: (values) => {
      values.owner_id = parentForm.values.owner_id;
      values.platform = parentForm.values.platform;

      return values;
    }
  });

  return <ResourceForm form={form} showBackButton={false} mx={0}>
    <Stack>
      <PasswordInput label="Username" {...form.getInputProps('credentials.api_key')} defaultVisible={!record} />

      <PasswordInput label="Password" {...form.getInputProps('credentials.api_token')} defaultVisible={!record} />

      <Group>
        <Button variant="outline" loading={isCheckingConnection}
                disabled={!form.isDirty () && (hasConnection || !form.values.credentials.api_key || !form.values.credentials.api_token)}
                onClick={() => checkConnection(form.values)}>
          Check Connection
        </Button>
      </Group>

      { hasConnection && <>
        <Divider my="xs" />

        <Title order={5} mb={0}>Automations</Title>

        <SimpleGrid cols={{ md: 2 }} spacing="lg">
          <Switch label="Sync equipment"
                  description="Automatically keeps imported equipment data up to date"
                  offLabel="No" onLabel="Yes"
                  {...form.getInputProps('settings.automation_vendsys_sync-equipment', { type: 'checkbox' })}
          />
        </SimpleGrid>
      </>}

      <Divider my="xs" />

      <Switch label="Enabled"
              description="Marks the integration as Active or Inactive"
              offLabel="No" onLabel="Yes"
              { ...form.getInputProps('is_active', { type: 'checkbox' }) }
      />

      <Card.Section withBorder px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Stack>
  </ResourceForm>;
}
