import { IconPointFilled } from '@tabler/icons-react';
import { CSSProperties } from 'react';

interface Props {
  color: string;
  size?: number;
  style?: CSSProperties | undefined;
}

export const Point = ({ color, size = 28, style }: Props) => {
  return <IconPointFilled color={color} size={size} style={{ verticalAlign: 'middle', ...style }} />
}
