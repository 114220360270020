import {
  Accordion, ActionIcon,
  Badge, Button, Card,
  createTheme,
  Drawer, Input, InputWrapper,
  Menu,
  NavLink,
  SegmentedControl,
  Switch,
  Table, Text,
  Tabs, Textarea,
  TypographyStylesProvider, Select, Tooltip,
  Indicator, Alert, List
} from '@mantine/core';
import { RichTextEditor } from '@mantine/tiptap';

import accordion from './src/styles/accordion.module.css';
import alert from './src/styles/alert.module.css';
import badge from './src/styles/badge.module.css';
import button from './src/styles/button.module.css';
import tabs from './src/styles/tabs.module.css';
import _switch from './src/styles/switch.module.css';
import _indicator from './src/styles/indicator.module.css';
import navlink from './src/styles/navlink.module.css';
import table from './src/styles/table.module.css';
import segmentedControl from './src/styles/segmented-control.module.css';
import drawer from './src/styles/drawer.module.css';
import inputWrapper from './src/styles/input-wrapper.module.css';
import card from './src/styles/card.module.css';
import textarea from './src/styles/textarea.module.css';
import input from './src/styles/input.module.css';
import actionIcon from './src/styles/action-icon.module.css';
import menu from './src/styles/menu.module.css';
import richtexteditor from './src/styles/richtexteditor.module.css';
import typography from './src/styles/typography.module.css';
import list from './src/styles/list.module.css';
import select from './src/styles/select.module.css';

export default createTheme({
  fontFamily: 'Inter, sans-serif',

  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em',
  },

  headings: {
    sizes: {
      h1: { fontWeight: '700' },
      h2: { fontWeight: '700' },
      h3: { fontWeight: '700' },
      h4: { fontWeight: '700' },
      h5: { fontWeight: '700' },
      h6: { fontWeight: '700' },
    },
  },

  components: {
    Accordion: Accordion.extend({
      classNames: {
        control: accordion.control,
        item: accordion.item,
      }
    }),
    Alert: Alert.extend({
      classNames: {
        icon: alert.icon,
      }
    }),
    Badge: Badge.extend({
      defaultProps: {
        variant: 'light'
      },
      classNames: {
        root: badge.root,
      }
    }),
    Button: Button.extend({
      defaultProps: {
        loaderProps: {
          // color: 'dark'
        }
      },
      classNames: {
        root: button.root,
      }
    }),
    Tabs: Tabs.extend({
      classNames: {
        root: tabs.root,
        tabLabel: tabs.tabLabel,
        panel: tabs.panel,
      }
    }),
    Switch: Switch.extend({
      classNames: {
        body: _switch.body,
        track: _switch.track,
        trackLabel: _switch.trackLabel,
        label: _switch.label,
      }
    }),
    Indicator: Indicator.extend({
      classNames: {
        indicator: _indicator.indicator,
      }
    }),
    NavLink: NavLink.extend({
      classNames: {
        children: navlink.children
      }
    }),
    Table: Table.extend({
      classNames: {
        table: table.table
      }
    }),
    SegmentedControl: SegmentedControl.extend({
      classNames: {
        root: segmentedControl.root
      }
    }),
    Drawer: Drawer.extend({
      classNames: {
        body: drawer.body,
        title: drawer.title,
      }
    }),
    TypographyStylesProvider: TypographyStylesProvider.extend({
      classNames: {
        root: typography.root,
      }
    }),
    InputWrapper: InputWrapper.extend({
      defaultProps: {
        inputWrapperOrder: ['label', 'input', 'description', 'error'],
      },
      classNames: {
        label: inputWrapper.label,
        error: inputWrapper.error,
      }
    }),
    Card: Card.extend({
      classNames: {
        root: card.root,
      }
    }),
    Text: Text.extend({
      defaultProps: {
        size: 'sm',
      }
    }),
    List: List.extend({
      classNames: {
        itemWrapper: list.itemWrapper,
      }
    }),
    Textarea: Textarea.extend({
      classNames: {
        input: textarea.input,
      }
    }),
    Input: Input.extend({
      classNames: {
        wrapper: input.wrapper,
      }
    }),
    ActionIcon: ActionIcon.extend({
      classNames: {
        root: actionIcon.root,
      }
    }),
    RichTextEditor: RichTextEditor.extend({
      classNames: {
        root: richtexteditor.root,
        toolbar: richtexteditor.toolbar,
        content: richtexteditor.content,
      }
    }),
    Menu: Menu.extend({
      classNames: {
        item: menu.item,
      }
    }),
    Select: Select.extend({
      defaultProps: {
        withCheckIcon: false,
        allowDeselect: false,
      },
      classNames: {
        option: select.option,
      }
    }),
    Tooltip: Tooltip.extend({
      defaultProps: {
        withArrow: true,
        position: 'top',
      },
    }),
  }
});
