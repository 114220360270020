import { IResourceComponentsProps } from "@refinedev/core";
import { IRefundListResponse } from '@interfaces';
import { FilterSelect } from '@components/filters/FilterSelect';
import { FilterDatePickerPopover } from '@components/filters/FilterDatePickerPopover';
import { FilterText } from '@components/filters/FilterText';
import { FilterBar } from '@components/filters/FilterBar';
import { RefundTable } from './table/RefundTable';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { Feature } from 'flagged';
import { Box, Divider, Flex, useMantineTheme } from '@mantine/core';
import { FilterToggle } from '@components/filters/FilterToggle';
import { useMediaQuery } from '@mantine/hooks';

export function RefundList({ initialData }: IResourceComponentsProps<IRefundListResponse>) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`, false);

  const filters = <>
    <Feature name="admin">
      <FilterSelect name="owners" label="Owners" route="owners" />
    </Feature>
    <FilterText name="reference" label="Reference" />
    <FilterSelect name="refund_name" label="Method" route="refund_method_names" />
    <FilterText name="tag_identifier" label="Tag ID" />
    <FilterDatePickerPopover name="disbursed_at" label="Disbursed" />
  </>;

  const quickFilters = <Flex direction={ isMobile ? 'column' : 'row'} gap="sm" style={{ flex: 1 }}>
    <FilterSelect name="status" label="Status" options={[
      { value: 'All', label: 'All' },
      { value: 'Draft', label: 'Draft' },
      { value: 'Pending', label: 'Pending' },
      { value: 'In Review', label: 'In Review' },
      { value: 'Approved', label: 'Approved' },
      { value: 'Declined', label: 'Declined' },
      { value: 'Completed', label: 'Completed' },
      { value: 'Returned', label: 'Returned' },
      { value: 'Expired', label: 'Expired' },
      { value: 'Blocked', label: 'Blocked' },
      { value: 'Failed', label: 'Failed' },
    ]} />

    <Feature name="refunds.has_automation">
      { !isMobile && <Divider orientation="vertical" style={{ height: 40 }} /> }
      <Box><FilterToggle name="automated_decision" label="Auto Approved" /></Box>
    </Feature>
  </Flex>;

  return <TableFilterProvider>
    <FilterBar quickFilters={quickFilters} filters={filters} />
    <RefundTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
