import { RichTextEditor } from '@mantine/tiptap';
import { useEditor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Placeholder from '@tiptap/extension-placeholder';
import { Color } from '@tiptap/extension-color';
import { Link } from '@tiptap/extension-link';
import TextStyle from '@tiptap/extension-text-style';
import { CharacterCount } from '@tiptap/extension-character-count';
import { Box, Group, Input, Text } from '@mantine/core';
import { TokenIndicator } from '@components/ui/editors/TokenIndicator';
import { useState } from 'react';
import { useDebouncedValue, useDidUpdate } from '@mantine/hooks';
import { TokensControl } from '@components/ui/editors/controls/TokensControl';
import { UndoControl } from '@components/ui/editors/controls/UndoControl';
import { RedoControl } from '@components/ui/editors/controls/RedoControl';
import { RestoreControl } from '@components/ui/editors/controls/RestoreControl';

interface Props {
  label?: string;
  placeholder?: string;
  originalText?: string;
  tokens?: string[];
  output?: 'html' | 'text';
  limit?: number;
  value?: any;
  error?: string;
  onChange: any;
  allowLinks?: boolean;
  allowHeaders?: boolean;
}

export const TranslateEditor = ({ label, originalText, tokens = [], output = 'html', limit, allowLinks = false, allowHeaders = false, ...props }: Props) => {
  const [value, setValue] = useState(props.value);
  const [debouncedValue] = useDebouncedValue(value, 200);

  useDidUpdate(() => {
    props.onChange(value);
  }, [debouncedValue]);

  const editor = useEditor({
    extensions: [
      StarterKit,
      Color,
      Link,
      TextStyle,
      Placeholder.configure({
        placeholder: () => props.placeholder || 'Fill in...'
      }),
      TokenIndicator.configure({ tokens }),
      limit && CharacterCount.configure({ limit }),
    ],
    content: value,
    onUpdate: ({ editor }) => {
      const content = output === 'html' ? editor.getHTML() : editor.getText();
      setValue(content === '<p></p>' ? '' : content);
    },
  });

  useDidUpdate(() => {
    if (props.value !== value) {
      editor?.commands?.setContent(props.value);
      setValue(props.value);
    }
  }, [props.value]);

  const hasTextToolbar = Boolean(output === 'text' && (!!tokens.length || originalText));

  return <Box>
    { label && <Input.Label>{ label }</Input.Label>}
    <RichTextEditor editor={editor} mod={{
      'vertical-toolbar': hasTextToolbar,
      invalid: !!props.error,
    }}>
      { output === 'html' && <>
        <RichTextEditor.Toolbar>
          <RichTextEditor.ControlsGroup>
            <UndoControl />
            <RedoControl />
          </RichTextEditor.ControlsGroup>
          <RichTextEditor.ControlsGroup>
            <RichTextEditor.Bold />
            <RichTextEditor.Italic />
            <RichTextEditor.ColorPicker colors={['#25262b', '#868e96', '#fa5252', '#e64980', '#be4bdb', '#7950f2', '#4c6ef5', '#228be6', '#15aabf', '#12b886', '#40c057', '#82c91e', '#fab005', '#fd7e14']} />
          </RichTextEditor.ControlsGroup>

          { allowHeaders && <RichTextEditor.ControlsGroup>
            <RichTextEditor.H3 />
            <RichTextEditor.H4 />
          </RichTextEditor.ControlsGroup>}

          { allowLinks && <RichTextEditor.ControlsGroup>
            <RichTextEditor.Link initialExternal />
            <RichTextEditor.Unlink />
          </RichTextEditor.ControlsGroup>}

          <RichTextEditor.ControlsGroup>
            { !!tokens.length && <TokensControl tokens={tokens} />}
            { originalText && <RestoreControl text={originalText} />}
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
      </>}

      { hasTextToolbar && <>
        <RichTextEditor.Toolbar>
          <RichTextEditor.ControlsGroup>
            { !!tokens.length && <TokensControl tokens={tokens} />}
            { originalText && <RestoreControl text={originalText} />}
          </RichTextEditor.ControlsGroup>
        </RichTextEditor.Toolbar>
      </>}

      <Box style={{ flex: 1 }}>
        <RichTextEditor.Content />

        { editor && limit && <Group justify="right">
          <Text c="dimmed" size="xs" m={8} mt={0}>
            { editor.storage.characterCount.characters() } / {limit}
          </Text>
        </Group>}
      </Box>
    </RichTextEditor>
    { !!props.error && <Input.Error>{ props.error }</Input.Error>}
  </Box>;
}
