import { CollapsableCard } from '@components/ui';
import { Loader } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useInvalidate, useResource, useUpdate } from '@refinedev/core';
import { useDebouncedCallback, useDidUpdate } from '@mantine/hooks';
import { ICustomerRecord, IOwnerRecord } from '@interfaces';
import * as Yup from 'yup';
import 'yup-phone-lite';
import { EMAIL_REGEX } from '@components/ui/EmailRegex';
import { useIdentity } from '@components/data/Identity.context';
import { setFormErrorsFromResponse } from '@components/ui/form/ResourceForm';
import { useTableExpandedRowResource } from '@components/table/TableExpandedRowResource.context';
import { VisitorIdBadge } from '@components/data/cards/CustomerCard/VisitorIdBadge';
import { CustomerFormFields } from '../../../../pages/customer/form/CustomerFormFields';

type Props = {
  customer: ICustomerRecord;
  onUpdated?: (data: ICustomerRecord) => void;
}

export const customerSchema = (owner: IOwnerRecord) => Yup.object().shape({
  name: Yup.string().required('Enter a name'),
  email: Yup.string().nullable().when({
    is: (v) => v,
    then: (schema) => schema.matches(EMAIL_REGEX, 'Enter a valid email'),
  })
    .when(['phone_number'], {
      is: (v) => !v,
      then: (schema) => schema.required('Enter a mobile or email'),
    }),
  phone_number: Yup.string().nullable().when({
    is: (v) => v,
    then: (schema) => schema.phone(owner?.country_code as any || 'US', 'Enter a valid mobile').required('Enter a mobile'),
  })
    .when(['email'], {
      is: (v) => !v,
      then: (schema) => schema.required('Enter a mobile or email'),
    }),
  locale: Yup.string().required('Select a language'),
}, [
  ['email', 'phone_number']
]);

export const CustomerCard = ({ customer, onUpdated }: Props) => {
  const { owner } = useIdentity();
  const { mutate: update, isLoading } = useUpdate<ICustomerRecord>();
  const { resource } = useResource();
  const tableResource = useTableExpandedRowResource();
  const invalidate = useInvalidate();

  const initialValues = (data = customer) => data;

  const form = useForm({
    validate: yupResolver(customerSchema(owner)),
    initialValues,
  });

  useDidUpdate(() => {
    form.setInitialValues(initialValues());
    form.reset();
  }, [customer]);

  const handleSave = useDebouncedCallback(() => {
    update({
      resource: 'customer',
      id: customer.id,
      values: form.values,
      successNotification: { type: 'success', message: 'Successfully updated customer details' },
    }, {
      onSuccess: (data) => {
        !!tableResource && invalidate({ resource: resource.name, invalidates: ['all'] });
        onUpdated && onUpdated(data.data);
      },
      onError: ({ response }) => {
        setFormErrorsFromResponse(response, form);
      },
    });
  }, 500);

  useDidUpdate(() => {
    if (!form.validate().hasErrors && form.values !== customer) {
      handleSave();
    }
  }, [form.values])

  return <CollapsableCard label="Customer"
                          collapsable={false}
                          leftSection={isLoading && <Loader size="xs" />}
                          rightSection={<VisitorIdBadge customer={customer} />}
  >
    <CustomerFormFields form={form} />
  </CollapsableCard>;
}
