import { IPayoutEventLogRecord } from '@interfaces';
import { EuiBasicTableColumn } from '@elastic/eui';
import PayoutEventLogExpandedRow from './PayoutEventLogExpandedRow';
import { DateTime } from '@components/ui/DateTime';
import { MemoryTable } from '@components/table/MemoryTable';

interface Props {
  events: IPayoutEventLogRecord[];
}

export const PayoutEventLogTable = ({ events }: Props) => {
  const columns = [
    {
      field: 'type',
      name: 'Type',
      sortable: true,
      truncateText: true,
    },
    {
      field: 'status',
      name: 'Status',
      sortable: true,
      truncateText: true
    },
    {
      field: 'created_at',
      name: 'Created',
      width: '180',
      sortable: true,
      dataType: 'date',
      render: (date) => <DateTime value={date} />
    },
  ] as EuiBasicTableColumn<IPayoutEventLogRecord>[];

  return <MemoryTable<IPayoutEventLogRecord>
    items={events}
    columns={columns}
    onRenderExpanded={PayoutEventLogExpandedRow}
  />;
}
