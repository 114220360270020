import { IResourceComponentsProps, useApiUrl, useCustomMutation } from '@refinedev/core';
import {
  Box,
  Card,
  Center,
  Divider,
  Grid,
  Group,
  Input,
  Loader, SegmentedControl, Select,
  SimpleGrid,
  Stack, Switch,
  Text,
  TextInput,
  Title
} from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { ResourceForm, setFormErrorsFromResponse } from '@components/ui/form/ResourceForm';
import { TagRules, tagRulesSchema } from '@components/ui/tagRules/TagRules';
import { INoticeResponse } from '@interfaces';
import { useEffect } from 'react';
import isEqual from 'lodash/isEqual';
import { CallOut } from '@components/ui/CallOut';
import { useDebouncedCallback, useDidUpdate } from '@mantine/hooks';
import { useIdentity } from '@components/data/Identity.context';
import { Translatable } from '@components/ui';
import { DateTimePicker } from '@mantine/dates';

export function NoticeEdit({ initialData: record }: IResourceComponentsProps<INoticeResponse>) {
  const { identity } = useIdentity();
  const { mutate, data: matches, isLoading, reset } = useCustomMutation();

  const apiUrl = useApiUrl();
  const languages = identity?.owner?.supported_languages ?? [];

  const translations = record?.data.translations || {
    title: {
      en: '',
      es: '',
      fr: ''
    },
    content: {
      en: '',
      es: '',
      fr: ''
    },
  };

  const schema = Yup.object().shape({
    name: Yup.string().required('Enter a name'),
    type: Yup.string().required('Select a type'),
    rules: Yup.lazy((_, { parent }) => {
      return parent.visibility === 'targeted' ? tagRulesSchema.rules : Yup.mixed().nullable().optional();
    }),
    translations: Yup.lazy(() => Yup.object({
      title: Yup.lazy(() => {
        const rules = languages.reduce((rules, lang) => {
          rules[lang] = Yup.string().required('Text is required');

          return rules;
        }, {});

        return Yup.object(rules).required();
      }),
      content: Yup.lazy(() => {
        const rules = languages.reduce((rules, lang) => {
          rules[lang] = Yup.string().required('Text is required');

          return rules;
        }, {});

        return Yup.object(rules).required();
      }),
    })),
    start_date: Yup.date().required('Select a start time'),
    end_date: Yup.lazy((_, { parent }) => {
      return Yup.date().nullable().when({
        is: () => !!parent.end_date,
        then: (schema) => {
          if (parent.end_date < parent.start_date) {
            return schema.min(parent.start_date, 'Time needs to be after start time');
          } else {
            return schema.min(new Date(), 'Time needs to be in the future');
          }
        }
      });
    }),
    visibility: Yup.string().required(),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      name: record?.data.name ?? '',
      type: record?.data.type ?? 'Notice',
      rules: record?.data.rules ?? null,
      translations: {
        title: {
          en: translations.title?.en ?? '',
          es: translations.title?.es ?? '',
          fr: translations.title?.fr ?? ''
        },
        content: {
          en: translations.content?.en ?? '',
          es: translations.content?.es ?? '',
          fr: translations.content?.fr ?? ''
        },
      },
      start_date: record?.data.start_date ? new Date(record?.data.start_date) :  new Date(),
      end_date: record?.data.end_date ? new Date(record?.data.end_date) :  null,
      is_active: record?.data.is_active ?? true,
      visibility: record?.data.rules ? 'targeted' : 'all'
    },
    onValuesChange: (values, previous) => {
      const hasRulesChanged = !isEqual(values.rules, previous.rules);

      if (hasRulesChanged) {
        const { hasError } = form.validateField('rules');

        if (!hasError && values.rules?.rules) {
          previewCount(values.rules);
        } else if (!values.rules?.rules.length) {
          reset();
        }
      }
    },
  });

  const previewCount = useDebouncedCallback((rules) => mutate({
    url: `${apiUrl}/tag_rules/preview/notice_matches`,
    method: 'post',
    values: { rules, id: record?.data.id }
  }, {
    onError: ({ response }) => {
      setFormErrorsFromResponse(response, form);
    },
  }), 500);

  useEffect(() => {
    if (record?.data.rules?.rules?.length) {
      previewCount(record.data.rules);
    }
  }, [record?.data]);

  useDidUpdate(() => {
    if (form.values.visibility === 'targeted') {
      form.setFieldValue('rules', { operator: 'and', rules: [] });
    } else {
      form.setFieldValue('rules', null);
    }
  }, [form.values.visibility]);

  return <ResourceForm form={form}>
    <Group mb="lg">
      <Title order={2}>Notice</Title>
    </Group>

    <Card shadow="sm" radius="sm">
      <Card.Section withBorder p="md">
        <Stack>
          <SimpleGrid cols={{ sm: 2 }} spacing="sm">
            <TextInput label="Name" { ...form.getInputProps('name') } />

            <Select label="Type"
                    data={['Notice', 'Announcement']}
                    {...form.getInputProps('type')}
            />
          </SimpleGrid>

          <Grid>
            <Grid.Col span={{ md: 12 }}>
              <Input.Label mb={0}>Title</Input.Label>
              <Translatable field="translations.title"
                            limit={75} />
            </Grid.Col>

            <Grid.Col span={{ md: 12 }}>
              <Input.Label mb={0}>Content</Input.Label>
              <Translatable field="translations.content"
                            limit={500}
                            output="html"
                            editorProps={{ allowHeaders: true, allowLinks: true }} />
            </Grid.Col>
          </Grid>

          <Divider />

          <SimpleGrid cols={{ sm: 2 }} spacing="sm">
            <DateTimePicker label="Start time"
                            valueFormat="DD MMM YYYY hh:mm A"
                            clearable
                            { ...form.getInputProps('start_date') }
            />

            <DateTimePicker label="End time (Optional)"
                            minDate={new Date()}
                            valueFormat="DD MMM YYYY hh:mm A"
                            clearable
                            { ...form.getInputProps('end_date') }
            />
          </SimpleGrid>

          <Divider />

          <Box>
            <Input.Label mb={0}>Visibility</Input.Label>
            <Input.Description mb={8}>Who should be seeing this notice?</Input.Description>
            <SegmentedControl
              w={200}
              data={[
                { label: 'All', value: 'all' as any },
                { label: 'Targeted', value: 'targeted' as any },
              ]}
              {...form.getInputProps('visibility')}
            />
          </Box>

          { form.values.visibility === 'targeted' && form.values.rules && <>
            <TagRules />
          </>}

          { (matches?.data || isLoading) && <>
            <CallOut>
              { isLoading && <Center>
                <Loader size="sm" />
              </Center>}

              { matches?.data && <>
                <Text size="sm">
                  Found <strong>{ matches.data.count }</strong> item matches for current rules.
                </Text>
              </>}
            </CallOut>
          </> }

          <Divider />

          <SimpleGrid cols={{ sm: 2 }} spacing="sm">
            <Switch label="Enabled"
                    description="Marks this notice as enabled or disabled."
                    offLabel="No" onLabel="Yes"
                    { ...form.getInputProps('is_active', { type: 'checkbox' }) }
            />
          </SimpleGrid>
        </Stack>
      </Card.Section>

      <Card.Section px="md" py="sm">
        <Group justify="right">
          <ResourceForm.CancelButton />
          <ResourceForm.SubmitButton />
        </Group>
      </Card.Section>
    </Card>
  </ResourceForm>
}
