import { getAllTimezones } from 'countries-and-timezones';

const timezones = Object.values(getAllTimezones())
  .filter((t) => {
    return t.name.includes('/') && !t.name.includes('Etc');
  })
  .sort((a, b) => a.utcOffset - b.utcOffset);

const formatTimezoneOption = (t) => ({
  value: t.name,
  label: `(${t.utcOffsetStr}) ${t.name}`
});

export const ALL_TIMEZONES = timezones.map(formatTimezoneOption);

export const US_TIMEZONES = timezones.filter((t) => t.countries.includes('US')).map(formatTimezoneOption);
export const CA_TIMEZONES = timezones.filter((t) => t.countries.includes('CA')).map(formatTimezoneOption);

export const getCountryTimezone = (country: string) => {
  country = country.toLowerCase();

  if (country === 'us') {
    return US_TIMEZONES;
  } else if (country === 'ca') {
    return CA_TIMEZONES;
  } else {
    return ALL_TIMEZONES;
  }
};
