import { ValidationError } from 'yup';
import { default_time_options } from './default_time_options';

export const isTimeBefore = (from: string, to: string) => {
  const [fromHours, fromMinutes] = from.split(':').map(Number);
  const [toHours, toMinutes] = to.split(':').map(Number);

  if (fromHours < toHours) return true;
  if (fromHours > toHours) return false;

  return fromMinutes <= toMinutes;
};

export const validateTimeRange = {
  name: 'time_range',
  test: (range, ctx) => {
    if (!range.from || !range.to) {
      return ctx.createError({
        path: ctx.path,
        message: 'Start and End times must be selected',
      });
    }

    if (!isTimeBefore(range.from, range.to)) {
      return ctx.createError({
        path: ctx.path,
        message: 'Start time must be before the End time.',
      });
    }

    return true;
  }
};

export const validateTimeRangeOverlaps = {
  name: 'time_range_overlaps',
  test: (slots, ctx) => {
    const errors = [];

    slots.forEach((range, i) => {
      const nextRange = slots[i + 1];

      if (range.to > nextRange?.from) {
        const overlapFrom = default_time_options.find(o => o.value === nextRange.from);
        const overlapTo = default_time_options.find(o => o.value === nextRange.to);

        errors.push(new ValidationError(
          `Times overlaps with ${overlapFrom.label} - ${overlapTo.label}`,
          null,
          `${ctx.path}.${i}`,
        ));
      }
    });

    if (errors.length) {
      return ctx.createError({
        message: () => errors,
      });
    }

    return true;
  }
};
