import { Context, createContext, PropsWithChildren, useContext } from 'react';

interface MenuContextProps<T = unknown> {
  id: number | string;
  record?: T
  closeMenu: () => void;
  openMenu: () => void;
}

export const ActionMenuContext = createContext<MenuContextProps>(null);

export const ActionMenuProvider = ({ children, ...props }: PropsWithChildren<MenuContextProps>) => {
  return <ActionMenuContext.Provider value={{ ...props }}>
    { children }
  </ActionMenuContext.Provider>
};

export function useActionMenu<T>() {
  const context = useContext<MenuContextProps<T>>(ActionMenuContext as Context<MenuContextProps<T>>);

  if (!context) {
    throw new Error('ActionMenu component was not found in the tree');
  }

  return context;
}
