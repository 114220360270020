import { IResourceComponentsProps, useNavigation } from '@refinedev/core';
import { ICustomerListResponse } from '@interfaces';
import { FilterBar } from '@components/filters/FilterBar';
import { CustomerTable } from './table/CustomerTable';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { FilterSingleRange } from '@components/filters/FilterSingleRange';
import { Feature } from 'flagged';
import { FilterSelect } from '@components/filters/FilterSelect';
import { Box, Button, Divider, useMantineTheme } from '@mantine/core';
import { useMediaQuery } from '@mantine/hooks';
import { IconPlus } from '@tabler/icons-react';

export function CustomerList({ initialData }: IResourceComponentsProps<ICustomerListResponse>) {
  const theme = useMantineTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.xs})`, false);
  const { create, list } = useNavigation();

  const actions = <>
    <Button leftSection={<IconPlus size={16} />}
            onClick={() => create('customer')}>
      New customer
    </Button>
  </>;

  const quickFilters = <>
    <FilterSelect name="marked_status" label="Status" options={[
      { label: 'All', value: 'All' },
      { label: 'None', value: 'None' },
      { label: 'Priority', value: 'Priority' },
      { label: 'Flagged', value: 'Flagged' },
      { label: 'Blocked', value: 'Blocked' },
    ]} />

    { !isMobile && <Divider orientation="vertical" style={{ height: 40 }} /> }

    <Box>
      <Button variant="light" onClick={() => list('request_link')}>
        View request links
      </Button>
    </Box>
  </>;

  const filters = <>
    <Feature name="admin">
      <FilterSelect name="owners" label="Owners" route="owners" />
    </Feature>
    <FilterSingleRange name="help_count" label="Help" min={0} max={100} />
    <FilterSingleRange name="feedback_count" label="Feedback" min={0} max={100} />
    <FilterSingleRange name="refunds_count" label="Refunds" min={0} max={100} />
    <FilterSingleRange name="voice_count" label="Calls" min={0} max={200} />
  </>;

  return <TableFilterProvider>
    <FilterBar actions={actions} quickFilters={quickFilters} filters={filters} />
    <CustomerTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
