import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { useIdentity } from '@components/data/Identity.context';
import { TranslateEditor } from '@components/ui/editors/TranslateEditor';
import { get, isObject } from 'lodash';
import { Tabs } from '../tabs/Tabs';

interface Props {
  field: string;
  languages?: string[];
  label?: string;
  tokens?: string[];
  limit?: number;
  output?: 'html' | 'text';
  editorProps?: {
    allowLinks?: boolean;
    allowHeaders?: boolean;
  };
}

const LANGUAGE_MAP = {
  en: 'English',
  es: 'Español',
  fr: 'French',
};

export const Translatable = ({ field, languages, label, tokens = [], limit, output = 'text', editorProps = {} }: Props) => {
  const { form } = useResourceForm();
  const { identity } = useIdentity();

  const defaultTranslations = identity?.default_translations;

  if (!languages) {
    if (identity?.owner?.supported_languages) {
      languages = identity.owner.supported_languages;
    } else {
      languages = ['en', 'es', 'fr'];
    }
  }

  const createFieldKey = (lang) => {
    return field.includes('_lang_') ? field.replace('_lang_', lang) : `${field}.${lang}`;
  };

  const getOriginalText = (key) => {
    if (defaultTranslations) {
      key = key.replace('translations.', '');
      const text = get(defaultTranslations, key);
      return isObject(text) ? null : text;
    }
  }

  return <Tabs defaultValue={languages[0]} orientation="vertical" mt="xs">
    <Tabs.List>
      {languages.map((lang, index) => {
        return <Tabs.Tab key={index} value={lang}>
          {LANGUAGE_MAP[lang]}
        </Tabs.Tab>
      })}
    </Tabs.List>

    {languages.map((lang, index) => {
      const fieldKey = createFieldKey(lang);
      const originalText = getOriginalText(fieldKey);

      return <Tabs.Panel value={lang} key={index} pl="md">
        <TranslateEditor label={label}
                         tokens={tokens}
                         originalText={originalText}
                         output={output}
                         limit={limit}
                         {...editorProps}
                         {...form.getInputProps(fieldKey)} />
      </Tabs.Panel>
    })}
  </Tabs>;
}
