import { ResourceForm } from '@components/ui/form/ResourceForm';
import { PropsWithChildren } from 'react';
import { IVoiceMessageSettingRecord } from '@interfaces';
import * as Yup from 'yup';
import { useForm, yupResolver } from '@mantine/form';
import { useIdentity } from '@components/data/Identity.context';
import convertFileToBase64 from '@libraries/convertFileToBase64';
import { useForceUpdate } from '@mantine/hooks';
import { difference } from 'lodash';
import { validateTimeRange, validateTimeRangeOverlaps } from './time_validatiors';

interface Props {
  record: IVoiceMessageSettingRecord;
}

export const SUPPORTED_AUDIO_FORMATS = [
  'audio/mpeg', 'audio/wav', 'audio/wave', 'audio/aiff', 'audio/x-aiff', 'audio/x-wav', 'audio/x-aifc', 'audio/x-gsm', 'audio/gsm', 'audio/ulaw'
];

export const VoiceMessageSettingsForm = ({ record, children }: PropsWithChildren<Props>) => {
  const { identity } = useIdentity();
  const owner = identity.owner;

  const settingsSchema = {
    handler: Yup.string(),
    answered: Yup.lazy((_, { parent }) => Yup.object({
      prompt_type: Yup.string(),
      voice_actor: Yup.string().nullable().when('prompt_type', {
        is: (v) => v === 'text' && parent.handler === 'answered',
        then: (schema) => schema.required('Select a value')
      }),
      voice_script: Yup.string().nullable().when('prompt_type', {
        is: (v) => v === 'text' && parent.handler === 'answered',
        then: (schema) => schema.required('Text is required')
      }),
      prompt_audio_path: Yup.mixed().nullable().when('prompt_type', {
        is: (v) => v === 'file' && parent.handler === 'answered',
        then: (schema) => schema
          .required('Select a file')
          .test('fileFormat', 'Unsupported Format', (value: any) => {
            if (!value || typeof value === 'string') return true;
            return SUPPORTED_AUDIO_FORMATS.includes(value?.type);
          })
      }),
    })),
    forwarded: Yup.lazy((_, { parent }) => Yup.object({
      phone_number: Yup.string().nullable().when({
        is: () => parent.handler === 'forwarded',
        then: (schema) => schema.phone(owner?.country_code as any || 'US', 'Enter a valid mobile').required('Enter a mobile')
      }),
      extension: Yup.string().nullable().when({
        is: () => parent.handler === 'forwarded',
        then: (schema) => schema
      }),
      delay: Yup.string().nullable().when({
        is: () => parent.handler === 'forwarded',
        then: (schema) => schema
      }),
    })),
  };

  const scheduleDay = {
    from: Yup.string().nullable(),
    to: Yup.string().nullable(),
    type: Yup.string(),
  };

  const schema = Yup.object().shape({
    active_settings: Yup.object().shape({ ...settingsSchema }),
    inactive_settings: Yup.object().shape({ ...settingsSchema }),
    schedule_settings: Yup.object({
      type: Yup.string(),
      timezone: Yup.string(),
      schedule: Yup.object({
        mon: Yup.array()
          .test(validateTimeRangeOverlaps)
          .of(Yup.object().shape({...scheduleDay}).test(validateTimeRange)),
        tue: Yup.array()
          .test(validateTimeRangeOverlaps)
          .of(Yup.object().shape({...scheduleDay}).test(validateTimeRange)),
        wed: Yup.array()
          .test(validateTimeRangeOverlaps)
          .of(Yup.object().shape({...scheduleDay}).test(validateTimeRange)),
        thu: Yup.array()
          .test(validateTimeRangeOverlaps)
          .of(Yup.object().shape({...scheduleDay}).test(validateTimeRange)),
        fri: Yup.array()
          .test(validateTimeRangeOverlaps)
          .of(Yup.object().shape({...scheduleDay}).test(validateTimeRange)),
        sat: Yup.array()
          .test(validateTimeRangeOverlaps)
          .of(Yup.object().shape({...scheduleDay}).test(validateTimeRange)),
        sun: Yup.array()
          .test(validateTimeRangeOverlaps)
          .of(Yup.object().shape({...scheduleDay}).test(validateTimeRange)),
      }),
    }),
  });

  const defaultScript = `Hi - thanks for calling ${owner.name}. Please leave a short message letting us know your name, the help you need, and the ID number displayed on any equipment or machine you need help with. Thank you.`;

  const defaultSettings = (data, type: string) => ({
    handler: data[type]?.handler ?? 'unanswered',
    answered: {
      prompt_type: data[type]?.answered?.prompt_type ?? 'text',
      voice_actor: data[type]?.answered?.voice_actor ?? 'woman|en-US',
      voice_script: data[type]?.answered?.voice_script ?? defaultScript,
      prompt_audio_path: data[type]?.answered?.prompt_audio_path ?? '',
    },
    forwarded: {
      phone_number: data[type]?.forwarded?.phone_number || '',
      extension: data[type]?.forwarded?.extension ?? '',
      delay: data[type]?.forwarded?.delay ?? '0',
    }
  });

  const initialValues = (data = record) => ({
    active_settings: defaultSettings(data, 'active_settings'),
    inactive_settings: defaultSettings(data, 'inactive_settings'),
    schedule_settings: {
      type: data.schedule_settings?.type ?? 'Inactive',
      timezone: data.schedule_settings?.timezone ?? owner.timezone,
      schedule: {
        mon: data.schedule_settings.schedule?.mon ?? [],
        tue: data.schedule_settings.schedule?.tue ?? [],
        wed: data.schedule_settings.schedule?.wed ?? [],
        thu: data.schedule_settings.schedule?.thu ?? [],
        fri: data.schedule_settings.schedule?.fri ?? [],
        sat: data.schedule_settings.schedule?.sat ?? [],
        sun: data.schedule_settings.schedule?.sun ?? [],
      },
    },
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues,
  });

  const onBeforeSave = async (values: IVoiceMessageSettingRecord) => {
    const activeAudioFile = values?.active_settings?.answered?.prompt_audio_path;
    const inactiveAudioFile = values?.inactive_settings?.answered?.prompt_audio_path;

    // @ts-ignore
    if (activeAudioFile instanceof File) {
      values.active_settings.answered.prompt_audio_path = await convertFileToBase64(activeAudioFile);
    }

    // @ts-ignore
    if (inactiveAudioFile instanceof File) {
      values.inactive_settings.answered.prompt_audio_path = await convertFileToBase64(inactiveAudioFile);
    }

    return values;
  }

  return <ResourceForm form={form}
                       id={record.id}
                       resource="voice_message/settings"
                       onBeforeSave={onBeforeSave}
                       showBackButton={false}
                       w="100%"
                       maw="100%"
                       disableRedirect
                       initialValuesFn={initialValues}
  >
    {children}
  </ResourceForm>;
}
