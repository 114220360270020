import { useState } from 'react';
import { Box, Text } from '@mantine/core';
import { useDidUpdate } from '@mantine/hooks';
import { Point } from '@components/ui/Point';

interface Props {
  status: string;
  includeWord?: boolean;
}

const COLOR_MAP = {
  '#D9E2EF': ['Closed', 'Neutral', 'Inactive', 'Draft', 'Disabled', 'Read'],
  '#ffc107': ['Open', 'Pending', 'Unread'],
  '#339af0': ['In Progress', 'In Review', 'Schedule'],
  '#df4759': ['Declined', 'Failure', 'Failed'],
  '#42ba96': ['Approved', 'Success', 'Active', 'Completed', 'Enabled'],
  '#767676': ['Returned', 'Expired', 'Blocked'],
};

const getColor = (status: string) => {
  let color;

  for (const [hex, statuses] of Object.entries(COLOR_MAP)) {
    if (statuses.includes(status)) {
      color = hex;
      break;
    }
  }

  return color;
}

export const StatusIndicator = ({ status, includeWord = true }: Props) => {
  const [color, setColor] = useState(getColor(status));

  useDidUpdate(() => {
    if (status) {
      setColor(getColor(status));
    }
  }, [status]);

  if (!status) return <Text ml={5} c="dimmed">&mdash;</Text>;

  return <>
    <Point color={color} />
    { includeWord && <Box ml={5}>{status}</Box> }
  </>;
}
