import React from 'react';
import { Button, Group, Input, Text, Box, Select, Stack, Alert, Grid } from '@mantine/core';
import { IconExclamationCircle, IconPlus } from '@tabler/icons-react';
import { useResourceForm } from '@components/ui/form/ResourceForm.context';
import { TagRule } from './TagRule';
import { ITagRule, ITagRules } from '@interfaces';
import * as Yup from 'yup';

export const tagRuleSchema = {
  column: Yup.string().required(),
  condition: Yup.string().when('column', {
    is: v => !!v,
    then: (schema) => schema.required(),
  }),
  value: Yup.mixed().nullable().when('condition', ([cond], schema) => {
    if (['in', 'notIn'].includes(cond)) {
      return Yup.array().min(1).required();
    } else if (['like', 'notLike', 'like:start', 'like:end'].includes(cond)) {
      return Yup.string().required();
    } else if (['expr', 'expr:not'].includes(cond)) {
      return Yup.string().required('Expression can not be empty');
    }

    return schema.optional();
  }),
};

export const tagRulesSchema = {
  rules: Yup.object().shape({
    rules: Yup.array().of(Yup.object().when({
      is: v => !v.operator,
      then: (schema) => schema.shape({
        ...tagRuleSchema
      }),
      otherwise: (schema) => schema.shape({
        rules: Yup.array().of(Yup.object().shape({
          ...tagRuleSchema
        })),
      }),
    })).min(1).required(),
  }),
};

export const TagRules = () => {
  const { form } = useResourceForm<ITagRules>();

  const renderFilters = (group: ITagRule, groupIndex, parentGroup) => {
    return group.rules.map((rule, index) => {
      const isGroup = !!rule.operator;

      const column = form.getInputProps(parentGroup ? `rules.rules.${groupIndex}.rules.${index}.column` : `rules.rules.${index}.column`);
      const condition = form.getInputProps(parentGroup ? `rules.rules.${groupIndex}.rules.${index}.condition` : `rules.rules.${index}.condition`);
      const value = form.getInputProps(parentGroup ? `rules.rules.${groupIndex}.rules.${index}.value` : `rules.rules.${index}.value`);

      return <Grid key={index} gutter={5} align="start">
        <Grid.Col span="content">
          {index === 0 && <Text fw={500} w={60} mt={7}>Where</Text>}
          {index === 1 && <>
            <Select
              w={60}
              mt={!isGroup ? 0 : 7}
              rightSectionWidth={20}
              data={[
                { value: 'and', label: 'And' },
                { value: 'or', label: 'Or' }
              ]}
              value={group.operator}
              onChange={(value) => {
                form.setFieldValue(!parentGroup ? 'rules.operator' : `rules.rules.${groupIndex}.operator`, value);
              }}
            />
          </>}
          {index > 1 && <Box w={60} pt={isGroup ? 16 : 7}>
            <Text fw={500} pl={13} tt="capitalize">
              {group.operator}
            </Text>
          </Box>}
        </Grid.Col>

        {!isGroup && <Grid.Col span="auto">
          <TagRule
            fields={{ column, condition, value }}
            onRemove={() => {
              if (parentGroup && group.rules.length <= 1) {
                form.removeListItem('rules.rules', groupIndex);
              }

              form.removeListItem(!parentGroup ? 'rules.rules' : `rules.rules.${groupIndex}.rules`, index)
            }}
          />
        </Grid.Col>}

        { isGroup && <Grid.Col span="auto">
          <Stack p="xs" gap="12" bg="gray.1" style={{ borderRadius: 4 }}>
            { renderFilters(rule, index, group) }

            <Box>
              <Button size="xs" variant="default"
                      leftSection={<IconPlus size={14} stroke={1.5} />}
                      onClick={() => form.insertListItem(`rules.rules.${index}.rules`, { column: '', condition: '', value: '' })}
              >
                Add Filter
              </Button>
            </Box>
          </Stack>
        </Grid.Col>}
      </Grid>
    });
  }

  return <Box>
    <Input.Label mb={0}>Rules</Input.Label>
    <Input.Description mb="xs" >
      Define rules to filter and group equipment based on specific criteria. Add multiple filters or groups to refine the selection further.
    </Input.Description>

    { form.errors['rules.rules'] && form.values.rules.rules.length === 0 && <>
      <Alert variant="light" color="red" p="xs" mb="xs" icon={<IconExclamationCircle />}>
        At least one rule is required
      </Alert>
    </>}

    <Stack gap={12}>
      { renderFilters(form.values.rules, 0, null) }
    </Stack>

    <Group gap="xs" mt={form.values.rules.rules.length ? 'xs' : 0}>
      <Button size="xs" variant="default"
              leftSection={<IconPlus size={14} stroke={1.5} />}
              onClick={() => form.insertListItem('rules.rules', { column: '', condition: '', value: '' })}
      >
        Add Filter
      </Button>
      <Button size="xs" variant="default"
              leftSection={<IconPlus size={14} stroke={1.5} />}
              onClick={() => form.insertListItem('rules.rules', { operator: 'and', rules: [{ column: '', condition: '', value: '' }]})}
      >
        New Group
      </Button>
    </Group>
  </Box>;
}
