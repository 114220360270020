import { useState } from 'react';
import { ActionIcon, Text, Loader, TextProps, Group, Flex, Input, InputProps } from '@mantine/core';
import { IconCheck, IconPencil, IconX } from '@tabler/icons-react';
import { useField } from '@mantine/form';

type Props = {
  text: string;
  onChange: (value: string) => void;
  isLoading?: boolean;
  textProps?: TextProps;
  inputProps?: InputProps | {
    maxLength?: number;
  };
}

export const EditableText = ({ text, onChange, isLoading = false, textProps, inputProps }: Props) => {
  const [isEditing, setIsEditing] = useState(false);

  const field = useField({
    initialValue: text,
  });

  const onConfirm = () => {
    if (field.getValue() !== text) {
      onChange(field.getValue());
    }

    setIsEditing(false);
  };

  const onCancel = () => {
    field.reset();
    setIsEditing(false);
  };

  const actions = () => {
    if (!isEditing) {
      return;
    }

    return <Flex wrap="nowrap" gap={5}>
      <ActionIcon variant="light" size="sm" color="green">
        <IconCheck size={20} strokeWidth={1.5} onClick={onConfirm} />
      </ActionIcon>
      <ActionIcon variant="light" size="sm" color="red">
        <IconX size={20} strokeWidth={1.5} onClick={onCancel} />
      </ActionIcon>
    </Flex>;
  };

  return <>
    { !isEditing && <Group gap="xs" wrap="nowrap">
      <Text {...textProps}>{ field.getValue() }</Text>

      { !isLoading && <ActionIcon variant="transparent" c="gray" size="md">
        <IconPencil size={22} strokeWidth={1.5} onClick={() => setIsEditing(true)} />
      </ActionIcon>}

      { isLoading && <Loader size="xs" /> }
    </Group>}

    { isEditing && <>
      <Input {...field.getInputProps()}
             autoFocus
             flex={1}
             maw={350}
             rightSectionWidth={isEditing ? 63 : 33}
             rightSection={actions()}
             rightSectionPointerEvents="all"
             styles={{
               input: {
                 background: 'transparent',
                 border: 'none',
                 borderBottom: '1px solid #ced4da',
                 borderRadius: 0,
               }
             }}
             onKeyDown={(e) => {
               if (e.key === 'Enter') {
                 onConfirm();
                 e.preventDefault();
               }

               if (e.key === 'Escape') {
                 onCancel();
                 e.preventDefault();
               }
             }}
             {...inputProps}
      />
    </>}
  </>;
};
