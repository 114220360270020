import { Button, Divider, Group, Input } from '@mantine/core';
import { IconPlus } from '@tabler/icons-react';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { FilterSearch } from '@components/filters/FilterSearch';
import { CustomerSearchTable } from './CustomerSearchTable';
import { useRequestState } from '@components/ui/drawers/Requests/RequestState.context';

export const CustomerSearchLayout = () => {
  const { newCustomer } = useRequestState();

  return <TableFilterProvider isolated>
    <Group justify="space-between" align="end">
      <Input.Wrapper label="Customer Search:" w={400}>
        <FilterSearch name="term" placeholder="Enter name, email or phone." />
      </Input.Wrapper>

      <Button leftSection={<IconPlus size={16} />} mb="1" onClick={newCustomer}>New customer</Button>
    </Group>

    <Divider my="md" size="md" />

    <CustomerSearchTable />
  </TableFilterProvider>
}
