import { CollapsableCard } from '@components/ui';
import { ICustomerRecord } from '@interfaces';
import { Button, Divider, Group, SimpleGrid } from '@mantine/core';
import { useForm, yupResolver } from '@mantine/form';
import { useCreate } from '@refinedev/core';
import { useRequestState } from '@components/ui/drawers/Requests/RequestState.context';
import { useIdentity } from '@components/data/Identity.context';
import { customerSchema } from '@components/data/cards/CustomerCard/CustomerCard';
import { setFormErrorsFromResponse } from '@components/ui/form/ResourceForm';
import { CustomerFormFields } from '../../../../pages/customer/form/CustomerFormFields';

export const CreateCustomer = () => {
  const { owner } = useIdentity();
  const { reset, setCustomer, customer } = useRequestState();
  const { mutate: create, isLoading: isSaving } = useCreate<ICustomerRecord>();

  const form = useForm<Partial<ICustomerRecord>>({
    validateInputOnBlur: true,
    validate: yupResolver(customerSchema(owner)),
    initialValues: {
      locale: 'en',
      name: customer.name,
      email: customer.email,
      phone_number: customer.phone_number,
      marked_status: 'None',
      settings: {
        feedback_exclude_automation: false,
        refunds_exclude_automation: false,
        notifications_unsubscribed: false,
      }
    }
  });

  const handleSubmit = async (values) => {
    create({ resource: 'customer', values }, {
      onSuccess: ({ data }) => setCustomer(data),
      onError: ({ response }) => {
        setFormErrorsFromResponse(response, form);
      },
    });
  };

  return <>
    <form onSubmit={form.onSubmit(handleSubmit)}>
      <SimpleGrid cols={{ md: 2 }} spacing="xs">
        <CollapsableCard label="New Customer" collapsable={false}>
          <CustomerFormFields form={form} />
        </CollapsableCard>
      </SimpleGrid>

      <Divider my="md" />

      <Group wrap="nowrap" gap="xs" justify="right">
        <Button variant="light" color="gray.8" onClick={reset}>Cancel</Button>
        <Button variant="filled" type="submit" loading={isSaving}>Create</Button>
      </Group>
    </form>
  </>;
}
