import { Button, Card, Group, Stack, Title, Text } from '@mantine/core';
import { useIdentity } from '@components/data/Identity.context';
import { useUpdate } from '@refinedev/core';
import Link from 'next/link';
import { Point } from '@components/ui/Point';

type Props = {
  hideLink?: boolean;
};

export const VoiceMessageSettingsForm = ({ hideLink = false }: Props) => {
  const { identity, refresh } = useIdentity();
  const { mutate: update, isLoading } = useUpdate();

  const owner = identity?.owner;

  const handleSubmit = ({ active }) => {
    const data = {
      settings: {
        'voice-messages_active': active,
      }
    }

    update({
      resource: 'owner/settings', id: identity.owner.id, values: data,
      successNotification: { type: 'success', message: 'Successfully updated settings.' }
    }, {
      onSuccess: refresh
    });
  };

  return <>
    <Stack gap="xl" my="md">
      { !owner.settings['voice-messages_active'] && <>
        <Card withBorder p="md" radius="sm">
          <Card.Section p="md" withBorder>
            <Title order={5} fw="500">Activate</Title>
          </Card.Section>

          <Card.Section withBorder p="md">
            <Text size="sm">
              By enabling, you accept that your data will be sent to OpenAI to assist  with processing, and furthermore you understand, agree and accept OpenAI's&nbsp;
              <a href="https://openai.com/policies/privacy-policy" target="_blank">Privacy Policy</a> and <a href="https://openai.com/policies/terms-of-use/" target="_blank">Terms of Use</a>.
            </Text>
          </Card.Section>

          <Card.Section px="md" py="sm">
            <Group justify="right">
              { !hideLink && <Link href="/settings/voice_message">
                <Button variant="default">View configuration</Button>
              </Link> }
              <Button type="submit"
                      variant="light"
                      onClick={() => handleSubmit({ active: true })}
                      loading={isLoading}>
                I agree and activate
              </Button>
            </Group>
          </Card.Section>
        </Card>
      </>}

      { owner.settings['voice-messages_active'] && <>
        <Card withBorder p="md" radius="sm">
          <Card.Section p="md" withBorder>
            <Group justify="space-between">
              <Title order={5} fw="500">
                <Point color="#42ba96" /> Voice messages enabled
              </Title>

              <Group>
                { !hideLink && <Link href="/settings/voice_message">
                  <Button variant="default">View configuration</Button>
                </Link> }
                <Button type="submit"
                        color="red"
                        variant="light"
                        onClick={() => handleSubmit({ active: false })}
                        loading={isLoading}>
                  Deactivate
                </Button>
              </Group>
            </Group>
          </Card.Section>
        </Card>
    </>}
    </Stack>
  </>;
}
