import { Menu } from '@mantine/core';
import { IconSend } from '@tabler/icons-react';
import { useActionMenu } from '@components/table/actions/ActionMenu.context';
import { modals } from '@mantine/modals';
import { IHelpListItem } from '@interfaces';

type Props = {};

export const CreateServiceTicketAction = ({ }: Props) => {
  const { closeMenu, id, record } = useActionMenu<IHelpListItem>();

  const openModal = () => modals.openContextModal({
    modal: 'createServiceTicket',
    title: `Create service ticket (${record.customer_name})`,
    innerProps: { helpId: id },
    size: 'lg',
  });

  return <>
    <Menu.Item
      color="blue"
      onClick={() => {
        closeMenu();
        openModal();
      }}
      leftSection={<IconSend size={18} stroke={1.5} />}
    >
      Create service ticket
    </Menu.Item>
  </>;
}
