import {
  ActionIcon,
  Alert, Button, Divider, Group,
  Loader, LoadingOverlay, NumberInput, Select, SimpleGrid, Stack
} from '@mantine/core';
import { ContextModalProps } from '@mantine/modals';
import { useCreate, useList, useOne } from '@refinedev/core';
import { IFilterOptionItem, IHelpRecord, IIntegrationVendSysRecord } from '@interfaces';
import { useIdentity } from '@components/data/Identity.context';
import { useForm, yupResolver } from '@mantine/form';
import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { IconAlertCircle, IconRefresh } from '@tabler/icons-react';
import { TextareaAutosize } from '@components/ui/TextareaAutosize';

type Props = {
  helpId: number
}

export const CreateServiceTicketModal = ({ context, id, innerProps }: ContextModalProps<Props>) => {
  const { owner } = useIdentity();
  const [useCache, setUseCache] = useState(1);
  const { mutate: create, isLoading: isSaving } = useCreate();

  const payload = { integration_id: owner.meta.vendsys_integration_id };

  const {
    data: complaintTypes,
    isLoading: isComplaintTypesLoading,
    isFetching: isComplaintTypesFetching,
    refetch: reloadComplaintTypes
  } = useList<IFilterOptionItem>({
    resource: `integration/vms/options/complaint_types?integration_id=${payload.integration_id}&cache=${useCache}`,
  });

  const {
    data: technicians,
    isLoading: isTechniciansLoading,
    isFetching: isTechniciansFetching,
    refetch: reloadTechnicians
  } = useList<IFilterOptionItem>({
    resource: `integration/vms/options/technicians?integration_id=${payload.integration_id}&cache=${useCache}`,
  });

  const { data: integration, isLoading: isIntegrationLoading } = useOne<IIntegrationVendSysRecord>({
    resource: 'integration', id: payload.integration_id
  });

  const { data: help, isLoading: isHelpLoading } = useOne<IHelpRecord>({
    resource: 'help', id: innerProps.helpId
  });

  const isLoading = isComplaintTypesLoading || isTechniciansLoading || isIntegrationLoading || isHelpLoading;

  const schema = Yup.object().shape({
    'complaint_id': Yup.mixed().required('Select a complaint type'),
    'technician_id': Yup.mixed().nullable().optional(),
    'default_asset_number': Yup.mixed().nullable().optional(),
    'priority': Yup.number()
      .transform(v => isNaN(v) ? undefined : v)
      .min(1, 'Must be 1 or higher')
      .max(10000, 'Must be lower than 10001')
      .required('Enter a priority, 1 - 10000'),
    'reason': Yup.string().nullable().max(255),
    'notes': Yup.string().nullable().max(255),
  });

  const form = useForm({
    validate: yupResolver(schema),
    initialValues: {
      complaint_id: `${integration?.data.settings['automation_vendsys_complaintId']}`,
      technician_id: `${integration?.data.settings['automation_vendsys_technicianId']}`,
      priority: integration?.data.settings['automation_vendsys_priority'] ?? 200,
      reason: help?.data.issue,
      notes: '',
    },
    transformValues: (values) => {
      if (values['complaint_id']) {
        values['complaint_id'] = Number(values['complaint_id']);
      }

      if (values['technician_id']) {
        values['technician_id'] = Number(values['technician_id']);
      }

      return values;
    }
  });

  useEffect(() => {
    if (integration?.data) {
      const values = {
        ...form.values,
        complaint_id: `${integration.data.settings['automation_vendsys_complaintId']}`,
        technician_id: `${integration.data.settings['automation_vendsys_technicianId']}`,
        priority: integration.data.settings['automation_vendsys_priority'],
      };

      form.setValues(values);
      form.setInitialValues(values);
    }
  }, [integration?.data]);

  useEffect(() => {
    if (help?.data) {
      const values = {
        ...form.values,
        reason: help.data.issue,
      };

      form.setValues(values);
      form.setInitialValues(values);
    }
  }, [help?.data]);

  const handleSubmit = (values) => {
    create({
      resource: `help/${innerProps.helpId}/service_ticket`, values
    }, {
      onSuccess: () => context.closeModal(id)
    });
  };

  const refreshOptions = (reload) => <ActionIcon variant="subtle" size="sm" title="Refresh">
    <IconRefresh size={20} strokeWidth={1.5} onClick={() => {
      setUseCache(0);
      reload();
    }} />
  </ActionIcon>;

  return <form onSubmit={form.onSubmit(handleSubmit)}>
    <Stack>
      <LoadingOverlay visible={isLoading} zIndex={1000} overlayProps={{ radius: "sm", blur: 2 }} />

      { help?.data.meta.service_call_id && <>
        <Alert icon={<IconAlertCircle size="1rem" />} variant="filled" color="orange" p={8}>
          NOTE: a service request has been created previously. To avoid duplication, ensure you need to create another before continuing.
        </Alert>
      </>}

      <Select
        label="Select complaint type"
        description="Used to set the complaint type of the service call"
        placeholder="Select a type..."
        leftSection={isComplaintTypesLoading || isComplaintTypesFetching ? <Loader size="xs" /> : refreshOptions(reloadComplaintTypes)}
        data={complaintTypes?.data || []}
        { ...form.getInputProps('complaint_id') }
      />

      <SimpleGrid cols={{ md: 2 }} spacing="lg">
        <Select
          label="Select a technician"
          description="Used to optionally assign a service call to a technician"
          placeholder="Select a technician..."
          leftSection={isTechniciansLoading || isTechniciansFetching ? <Loader size="xs" /> : refreshOptions(reloadTechnicians)}
          data={technicians?.data || []}
          { ...form.getInputProps('technician_id') }
        />

        <NumberInput label="Priority"
                     description="Sets the priority of the service call (1 - 10000)"
                     min={1}
                     max={10000}
                     {...form.getInputProps('priority')} />
      </SimpleGrid>

      <TextareaAutosize label="Reason (Optional)"
                        description="Defaults to the description text from the help request"
                        {...form.getInputProps('reason')}  />

      <TextareaAutosize label="Notes (Optional)" {...form.getInputProps('notes')}  />

      <Divider />

      <Group wrap="nowrap" gap="xs" justify="right">
        <Button variant="light" color="gray.8" onClick={() => context.closeModal(id)}>Cancel</Button>
        <Button variant="filled" type="submit" loading={isSaving}>Create</Button>
      </Group>
    </Stack>
  </form>;
};
