import { useApiUrl, useCustomMutation } from '@refinedev/core';
import { useClipboard } from '@mantine/hooks';

export const useCreatePreAuthFollowUpLink = () => {
  const apiUrl = useApiUrl();
  const mutation = useCustomMutation();
  const clipboard = useClipboard({ timeout: 1000 });

  const copyLink = (refundId) => mutation.mutate({
    method: 'post',
    url: `${apiUrl}/refund/${refundId}/create_pre_auth_follow_up_link`,
    values: {},
    successNotification: { type: 'success', message: 'Link copied to clipboard.' }
  }, {
    onSuccess: (data) => {
      clipboard.copy(data.data);
    }
  });

  return {
    ...mutation,
    copyLink
  };
};
