import { ReactNode, useEffect, useState } from 'react';
import { Button, Collapse, Divider, Grid, Group, useMantineTheme, Stack, Paper } from '@mantine/core';
import { IconEraser, IconFilter } from '@tabler/icons-react';
import { useMediaQuery } from '@mantine/hooks';
import { useTableFilters } from '@components/table/TableFilter.context';
import { FilterSearch } from '@components/filters/FilterSearch';
import { FilterDatePicker } from '@components/filters/FilterDatePicker';
import { isEqual } from 'lodash';

type Props = {
  actions?: ReactNode | ReactNode[];
  quickFilters?: ReactNode | ReactNode[];
  filters?: ReactNode | ReactNode[];
  withDatePicker?: boolean;
  withSearch?: boolean;
};

export const FilterBar = ({ actions, quickFilters, filters, withDatePicker = true, withSearch = true }: Props) => {
  const theme = useMantineTheme();
  const { filters: activeFilters, defaultFilters, clearFilters } = useTableFilters();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.sm})`, false);
  const [filtersOpened, setFiltersOpened] = useState(false);
  const [canClearFilters, setCanClearFilters] = useState(false);

  useEffect(() => {
    setCanClearFilters(!isEqual(defaultFilters, activeFilters));
  }, [activeFilters]);

  const renderSearch = () => {
    return withSearch && <FilterSearch name="term" />;
  };

  const renderDatePicker = () => {
    return <FilterDatePicker name="created_at" />
  };

  const renderFilters = () => {
    return <Grid align="center">
      <Grid.Col span={{ lg: 9 }} p={ filters || canClearFilters ? 8 : 0 } >
        <Group gap="xs">
          { !isMobile && filters && <IconFilter strokeWidth={1} size={20} /> }

          { filters }

          { canClearFilters &&
            <Button color="gray"
                    variant="subtle"
                    leftSection={<IconEraser strokeWidth={1} size={20} />}
                    onClick={clearFilters}
            >
              Reset filters
            </Button>
          }
        </Group>
      </Grid.Col>
      <Grid.Col span={{ lg: 3 }} p={ withDatePicker ? 8 : 0 } style={{ alignSelf: 'start' }}>
        { withDatePicker && renderSearch() }
      </Grid.Col>
    </Grid>;
  };

  return <Paper withBorder p="sm" mb="md">
    <Grid>
      <Grid.Col span={{ lg: 9 }} p={ actions || quickFilters ? 8 : 0 }>
        <Group gap="xs">
          { actions }
          { quickFilters }
        </Group>
      </Grid.Col>
      { (withDatePicker || withSearch) && <Grid.Col span={{ lg: 3 }}>
        { withDatePicker ? renderDatePicker() : renderSearch() }
      </Grid.Col>}
    </Grid>
    {
      (filters || (withDatePicker && withSearch)) && <>
        <Divider my="xs"/>
        <Stack align={isMobile && filters ? 'flex-start' : 'stretch'} gap="sm">
          {
            isMobile && filters &&
            <Button size="xs"
                    variant={filtersOpened ? 'outline' : 'default'}
                    onClick={() => setFiltersOpened(!filtersOpened)}
                    leftSection={<IconFilter strokeWidth={1.5} size={20} />}
            >
              {filtersOpened ? 'Less' : 'More'} filters
            </Button>
          }

          {
            isMobile && filters ?
              <Collapse in={filtersOpened}>{renderFilters()}</Collapse> :
              renderFilters()
          }
        </Stack>
      </>
    }
  </Paper>
}
