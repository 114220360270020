import { IHelpListItem } from '@interfaces';
import { ResourceTable, ResourceTableProps } from '@components/table/ResourceTable';

import HelpTableColumns from './HelpTableColumns';
import HelpTableExpandedRow from './HelpTableExpandedRow';

export const HelpTable = (props: ResourceTableProps<IHelpListItem>) => {
  return <ResourceTable<IHelpListItem>
    resource="help"
    columns={HelpTableColumns}
    onRenderExpanded={HelpTableExpandedRow}
    excludeColumns={['id']}
    {...props}
    tableProps={{
      ...props.tableProps,
      rowProps: (record) => ({
        'data-flagged': record.customer_marked_status === 'Flagged',
        'data-blocked': record.customer_marked_status === 'Blocked',
        'data-priority': record.customer_marked_status === 'Priority',
      })
    }}
  />;
}
