import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { IOwnerRecord, IProfileRecord } from '@interfaces';
import { API_URL } from '../../../pages/_app';
import { zippyDataProvider } from '../../providers';
import { useSwitchOwner } from '@components/hooks/useSwitchOwner';
import { useInvalidate, useLogout } from '@refinedev/core';
import { IWalletRecord } from '../../interfaces/data/wallet';

type Props = {
  initialIdentity?: IProfileRecord;
}

type IdentityContextProps = {
  identity: IProfileRecord;
  owner?: IOwnerRecord;
  wallet?: IWalletRecord;
  isOrgSwitching: boolean;
  refresh: () => Promise<void>;
  refreshWallet: () => Promise<void>;
  logout: () => void;
  switchOrganisation: (id: number | string) => Promise<void>;
};

export const IdentityContext = createContext<IdentityContextProps>(null);

const emptyWallet = {
  balance: 0,
  pending_balance: 0,
  meta: {
    currency: 'USD',
    credit: 0,
    top_up_amount: 0,
    has_top_up_enabled: false
  }
};

// As this function is not tsx and can not access `useIdentity, but profile's access can change between organisations.
// The latest identity data is shared this object, the setter can be found in the `refreshProfile` call.
export let identityData = null;

export const IdentityProvider = ({ children, initialIdentity = null }: PropsWithChildren<Props>) => {
  const { switchOwner } = useSwitchOwner();
  const { mutate: baseLogout } = useLogout();
  const invalidate = useInvalidate();

  const [ identity, setIdentity ] = useState<IProfileRecord>(initialIdentity);
  const [ wallet, setWallet ] = useState<IWalletRecord>(initialIdentity?.owner?.wallet ?? emptyWallet);
  const [ isOrgSwitching, setIsOrgSwitching ] = useState(false);

  const refreshProfile = async () => {
    const client = zippyDataProvider(API_URL);
    const { data } = await client.custom<IProfileRecord>({
      url: `${API_URL}/user/profile`,
      method: 'get',
    });

    setIdentity(data);
    identityData = Object.freeze(data);
  };

  const refreshWallet = async () => {
    const client = zippyDataProvider(API_URL);
    const { data } = await client.custom<IWalletRecord>({
      url: `${API_URL}/wallet`,
      method: 'get',
    });

    setWallet(data);
  };

  const switchOrganisation = async (id) => {
    setIsOrgSwitching(true);
    await switchOwner(id);

    setTimeout(() => {
      refreshProfile();
      refreshWallet();
    }, 1000);

    setTimeout(() => {
      invalidate({ invalidates: ['all'] });
      setIsOrgSwitching(false);
    }, 2000);
  }

  const logout = () => {
    baseLogout();
    setIdentity(null);
    identityData = null;
  }

  return <IdentityContext.Provider value={{
    identity: identity ?? initialIdentity,
    owner: identity?.owner ?? initialIdentity?.owner,
    wallet: wallet ?? initialIdentity?.owner?.wallet,
    isOrgSwitching,
    refresh: refreshProfile,
    refreshWallet: refreshWallet,
    logout,
    switchOrganisation
  }}>
    { children }
  </IdentityContext.Provider>
};

export function useIdentity() {
  const context = useContext(IdentityContext);

  if (!context) {
    throw new Error('useIdentity hook was called outside of IdentityProvider context');
  }

  return context;
}
