import { ReactNode } from 'react';
import { useDelete, useUpdate, useCreate } from "@refinedev/core";

type OnUpdateProps = {
  onCreate: (values: unknown) => void;
  onUpdate: (values: unknown) => void;
  onDelete: () => void;
  isLoading: boolean;
}

type Props = {
  id?: string | number;
  resource: string;
  children: (props: OnUpdateProps) => ReactNode;
}

export const ResourceItem = ({ id, resource, children }: Props) => {
  const { mutate: create, isLoading: isCreateLoading } = useCreate();
  const { mutate: update, isLoading: isUpdateLoading } = useUpdate();
  const { mutate: destroy, isLoading: isDestroyLoading } = useDelete();

  const isLoading = isCreateLoading || isUpdateLoading || isDestroyLoading;

  const onCreate = (values: unknown) => {
    create({ resource, values });
  };

  const onUpdate = (values: unknown) => {
    update({ resource, id, values });
  };

  const onDelete = () => {
    destroy({ resource, id });
  };

  return <>{ children({ onCreate, onUpdate, onDelete, isLoading }) }</>;
}
