import { createContext, PropsWithChildren, useContext, useState } from 'react';
import { LogicalFilter, useResource } from "@refinedev/core";
import { useRouter } from 'next/router';
import qs from 'qs';
import { cloneDeep, unionBy } from 'lodash';
import { useIdentity } from '@components/data/Identity.context';
import { useDidUpdate } from '@mantine/hooks';

type FilterReset = {
  name: string;
  callback: () => void;
};

type TableFilterContextProps = {
  filters: LogicalFilter[];
  initialFilters?: LogicalFilter[];
  defaultFilters?: LogicalFilter[];
  setFilter?: (name: string, value: unknown) => void;
  setFilterReset?: (name: string, callback: () => void) => void;
  clearFilters?: () => void;
  excludeDefaultFilters?: boolean;
};

type Props = {
  isolated?: boolean;
};

export const TableFilterContext = createContext<TableFilterContextProps>(null);

export const TableFilterProvider = ({ isolated = false, children }: PropsWithChildren<Props>) => {
  const router = useRouter();
  const { resource } = useResource();
  const { identity } = useIdentity();
  const queryString = router.asPath.split('?')[1] ?? router.asPath;
  const parsedQuery = qs.parse(queryString, {
    ignoreQueryPrefix: true,
    decoder: (str, defaultDecoder, charset, type) => {
      // Do not parse dates or date times, turns "+" into space.
      if (type === 'value' && Date.parse(str)) {
        return str;
      } else if (type === 'value' && (str === 'true' || str === 'false')) {
        return str === 'true';
      }

      return defaultDecoder(str);
    }
  });

  const defaultFilters = isolated ? [] : (resource?.meta?.defaultFilters ?? []);
  const queryFilters = isolated ? [] : (parsedQuery?.filters ?? []);

  // @ts-ignore
  const initialFilters = unionBy<LogicalFilter>([...queryFilters], cloneDeep(defaultFilters), 'field');

  const [filters, setFilters] = useState<LogicalFilter[]>(initialFilters);
  const [resets, setResets] = useState<FilterReset[]>([]);

  // Clear filters when the account is switched
  useDidUpdate(() => clearFilters(), [identity]);

  const setFilter = (name, value) => {
    setFilters((filters) => {
      const filter = filters.find(f => f.field === name);
      const hasValue = Array.isArray(value) ? value.length > 0 : !!value;

      if (filter && hasValue) {
        filter.value = value;
      } else if (!filter && hasValue) {
        filters.push({ field: name, value } as LogicalFilter);
      } else {
        filters = filters.filter(f => f.field !== name);
      }

      return [...filters];
    });
  }

  const setFilterReset = (name, callback) => {
    resets.push({ name, callback });
    setResets(resets);
  }

  const clearFilters = () => {
    resets.forEach(f => f.callback());
  }

  return <TableFilterContext.Provider value={{ filters, initialFilters, defaultFilters, setFilter, setFilterReset, clearFilters, excludeDefaultFilters: isolated }}>
    { children }
  </TableFilterContext.Provider>
};

export function useTableFilters() {
  const context = useContext(TableFilterContext);

  if (!context) {
    throw new Error('useTableFilters hook was called outside of TableFilterProvider context');
  }

  return context;
}
