import { IResourceComponentsProps } from "@refinedev/core";
import { IVoiceMessageSettingListResponse } from '@interfaces';
import { VoiceMessageSettingTable } from './table/VoiceMessageSettingTable';
import { useFeatures } from 'flagged';
import { FilterBar } from '@components/filters/FilterBar';
import { FilterSelect } from '@components/filters/FilterSelect';
import { TableFilterProvider } from '@components/table/TableFilter.context';
import { VoiceMessageSettingsForm } from '../../voice_message/form/VoiceMessageSettingsForm';

export function VoiceMessageSettingList({ initialData }: IResourceComponentsProps<IVoiceMessageSettingListResponse>) {
  const features = useFeatures();

  const filters = features.admin ? <FilterSelect name="owners" label="Owner" route="owners" /> : null;

  return <TableFilterProvider>
    { filters && <FilterBar filters={filters} withDatePicker={false} /> }

    { !features.admin && <VoiceMessageSettingsForm hideLink /> }

    <VoiceMessageSettingTable initialData={initialData} syncWithLocation={true} />
  </TableFilterProvider>;
}
