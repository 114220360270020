import { PropsWithChildren } from 'react';
import { ActionIcon, Menu } from '@mantine/core';
import { IconDotsVertical } from '@tabler/icons-react';
import { ActionMenuProvider } from '@components/table/actions/ActionMenu.context';
import { useDisclosure } from '@mantine/hooks';

type Props = {
  id: number | string;
  record?: unknown;
};

export const ActionMenu = ({ id, record, children }: PropsWithChildren<Props>) => {
  const [opened, { open, close }] = useDisclosure(false);

  return <ActionMenuProvider id={id} record={record} openMenu={open} closeMenu={close}>
    <Menu trigger="click"
          shadow="sm"
          position="left"
          withArrow
          trapFocus
          offset={2}
          closeOnItemClick={false}
          opened={opened}
          onChange={(v) => v ? open() : close()}
          styles={{ dropdown: { minWidth: 140 }}}
    >
      <Menu.Target>
        <ActionIcon variant="subtle" styles={{ root: { background: opened ? 'var(--mantine-color-blue-light-hover)' : null } }}>
          <IconDotsVertical stroke={1.5} size={20} />
        </ActionIcon>
      </Menu.Target>

      <Menu.Dropdown>
        { children }
      </Menu.Dropdown>
    </Menu>
  </ActionMenuProvider>
}
